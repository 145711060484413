import React from "react";
import "./Home.scss";
import { NavLink } from "react-router-dom";
import DOMPurify from "dompurify";
function HomeBanner(props) {
  let homeBuilders = props.homeBuilder;
  const sanitizeHTML = (homeBuilders) => {
    let cleanHTML = DOMPurify.sanitize(homeBuilders);
     return cleanHTML.replace(/<\/p>|<p>|<\/p>$/g, "").replace(/^<p>$/g, "");
  };
  return (
    <>
      <section className="head2">
        <div className="container pb-4">
          <div className="row">
            <div className="col-lg-6  banners">
              {homeBuilders.group.headerText && (
                <div className="d-flex justify-content-start">
                  <h3
                    dangerouslySetInnerHTML={{
                      __html: sanitizeHTML(homeBuilders.group.headerText),
                    }}
                  />
                  <hr className="hr" />
                </div>
              )}

              <h1
                dangerouslySetInnerHTML={{
                  __html: homeBuilders.group.heading,
                }}
              />
              
              <p
              className="paragraph"
                dangerouslySetInnerHTML={{
                  __html: sanitizeHTML(homeBuilders.group.paragraph),
                }}
              />
              <button className="btn btns">
                <NavLink className="navlink" to={`${homeBuilders.group.button.url}`}>
                  {homeBuilders.group.button.title}
                </NavLink>
              </button>
            </div>
            <div className="col-lg-6 two">
              <img
                src={homeBuilders.group.images.node.sourceUrl}
                className="img-fluid banner-img"
                alt="Image"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default HomeBanner;
