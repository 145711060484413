import React, { useEffect, useState } from "react";
import "./Cardsecond.scss";
import SanitizeHTML from "../../SanitizeHTML";
function Cardsecond(props) {
  const second = props.homeBuilder;
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1279);
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1279);
    };

    window.addEventListener("resize", handleResize);

    window.AOS.init();
window.AOS.refresh();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <section className="cards-second " style={{ background: "#F7FAFD" }}>
      <div className="container">
        <div className="row  py-lg-3 py-0">
          <div className="col-12 text-center my-3">
            <h2 dangerouslySetInnerHTML={{ __html: second.heading }} />

            <div className="d-flex animate justify-content-cebter">
              <div className="blues"></div>
              <div className="lines"></div>
            </div>
            <p
              dangerouslySetInnerHTML={{
                __html: SanitizeHTML(second.descriptionsifAny),
              }}
              className="paragraph"
            />
          </div>
          </div>

{isSmallScreen ? (
  < div className="row">
    {second.createcards.slice(0,6).map((el, index) => (
      <div
        className=" col-lg-4 col-sm-6 border-card  my-5"
        key={index}
        style={{ "--borderColor": `${el.border}` }}
      >
        <div className="borders">
          <div className="card">
            <div className="card-body">
              <h3>{el.cardshead}</h3>
              <p
                className="para"
                dangerouslySetInnerHTML={{
                  __html: SanitizeHTML(el.cardsdescriptions),
                }}
              />
            </div>
          </div>
          <div className="border d-flex justify-content-center" />
        </div>
      </div>
    ))}
    <div className="row" >
    {second.createcards.slice(6,9).map((el, index) => (
      <div
        className=" col-lg-4 col-sm-6 border-card my-5"
        key={index}
        style={{ "--borderColor": `${el.border}` }}
      >
        <div className="borders">
          <div className="card">
            <div className="card-body">
              <h3>{el.cardshead}</h3>
              <p
                className="para"
                dangerouslySetInnerHTML={{
                  __html: SanitizeHTML(el.cardsdescriptions),
                }}
              />
            </div>
          </div>
          <div className="border d-flex justify-content-center" />
        </div>
      </div>
    ))}
    </div>
    </div>
):(
  <div className="row">
  {second.createcards.map((el, index) => (
    <div
      className=" col-3 border-card my-5"
      key={index}
      style={{ "--borderColor": `${el.border}` }}
    >
      <div className="borders">
        <div className="card">
          <div className="card-body">
            <h3>{el.cardshead}</h3>
            <p
              className="para"
              dangerouslySetInnerHTML={{
                __html: SanitizeHTML(el.cardsdescriptions),
              }}
            />
          </div>
        </div>
        <div className="border d-flex justify-content-center" />
      </div>
    </div>
  ))}
  </div>
)}
       
        </div>
    </section>
  );
}

export default Cardsecond;
