import { useEffect } from "react";
import React from "react";
import "./Home/Perk/Perk.scss";
import { NavLink } from "react-router-dom";
import DOMPurify from "dompurify";
function BulletList(props) {
  useEffect(() => {
    window.AOS.init();
  }, []);
  const homeBuilders = props.homeBuilder;
  const sanitizeHTML = (homeBuilders) => {
    let cleanHTML = DOMPurify.sanitize(homeBuilders);
    return cleanHTML.replace(/<\/p>|<p>|<\/p>$/g, "").replace(/^<p>$/g, "");
  };

  return (
    <>
      <section
        className="perks py-xxl-5 py-xl-4"
        style={{ background: `${homeBuilders.bgColor}` }}
      >
        <div
          className="container"
          data-aos="fade-up"
          data-aos-once="true"
          data-aos-anchor-placement="top-center"
          data-aos-offset="50"
          data-aos-duration="1000"
        >
          <div className="row flex">
            <div className="col-lg-6 my-lg-4">
              <h2>{homeBuilders.rowcontainer.col2.col2head}</h2>

              <div className="d-flex animate justify-content-start">
                <div className="blue"></div>
                <div className="line"></div>
              </div>
              <p
                className="paragraph"
                dangerouslySetInnerHTML={{
                  __html: sanitizeHTML(homeBuilders.rowcontainer.col2.coltext),
                }}
              />
              <div className="container-fluid  pt-5">
                <div className="position">
                  <div className="row">
                    {homeBuilders.rowcontainer.col2.col2cards.map((el,index) => (
                      <div className="col-lg-6 col-sm-6  " key={index}>
                        <div className=" d-flex justify-content-start point ">
                          <div className="blue-circle me-2">
                            <div></div>
                          </div>
                          <h3>{el.cardstext}</h3>
                        </div>
                        <p
                          className="para"
                          dangerouslySetInnerHTML={{
                            __html: sanitizeHTML(el.cardsDescription),
                          }}
                        />
                      </div>
                    ))}
                  </div>

                    {/* {homeBuilders.column2.anothercardsif && (
                      <>
                        <hr />
                        <div className="container ">
                          <div className="row move bg">
                            {homeBuilders.column2.anothercardsif.map(
                              (el, index) => (
                                <div
                                  className="col-lg-6   col-sm-6 "
                                  style={{ padding: "22px 0 0 27px " }}
                                  key={index}
                                >
                                  <h3>{el.head}</h3>
                                  <p
                                    className="para"
                                    dangerouslySetInnerHTML={{
                                      __html: sanitizeHTML(el.description),
                                    }}
                                  />
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      </>
                    )} */}
                  {/* {homeBuilders.buttonNameifAny && (
                    <button className="btn btns">
                      <NavLink
                        className="navlink"
                        to={homeBuilders.buttonNameifAny.url}
                      >
                        {homeBuilders.buttonNameifAny.title}
                      </NavLink>
                    </button>
                  )} */}
                </div>
              </div>
            </div>
            <div className="col-lg-6 ">
              <img
                src={homeBuilders.rowcontainer.colimage.node.sourceUrl}
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default BulletList;
