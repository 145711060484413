import React, { useEffect, useState } from "react";
import "./Digital.scss";
import DOMPurify from "dompurify";
function Service(props) {
  const cards = props.homeBuilder;
  const sanitizeHTML = (homeBuilders) => {
    let cleanHTML = DOMPurify.sanitize(homeBuilders);
    return cleanHTML.replace(/<\/p>|<p>|<\/p>$/g, "").replace(/^<p>$/g, "");
  };
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1024);
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1024);
    };

    window.addEventListener("resize", handleResize);

    window.AOS.init();
window.AOS.refresh();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className="digital-marketing"
      style={{ backgroundColor: "#f7fafd" }}
    >
      <div className="container py-lg-5">
        <div className="row">
          <div className="col-lg-6">
            <img
              src={cards.rowcontainer.colimage.node.sourceUrl}
              alt="img"
              className="img-fluid"
            />
          </div>
          <div className="col-lg-6 col">
            <h2>{cards.rowcontainer.col2.col2head}</h2>
            {isSmallScreen ? (
               <div className="d-flex animate ">
               <div className="blue"></div>
               <div className="line"></div>
             </div>
            ): (
              <div className="d-flex animate ">
              <div className="blue-xl"></div>
              <div className="line-xl"></div>
            </div>
            )}
           
            <p
              className="paragraph"
              dangerouslySetInnerHTML={{
                __html: sanitizeHTML(cards.rowcontainer.col2.coltext),
              }}
            />
            {cards.rowcontainer.col2.col2cards.map((el, index) => (
              <div className="col-12" key={index}>
                <div className="card  ">
                  <img
                    src={el.cardsicon.node.sourceUrl}
                    className="img-fluid"
                  />
                  <div className="card-body">
                    <h3>{el.cardstext}</h3>
                    <p className="para"
                      dangerouslySetInnerHTML={{
                        __html: sanitizeHTML(el.cardsDescription),
                      }}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Service;
