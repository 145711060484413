import "./App.scss";
import {  Route, Routes, useLocation } from "react-router-dom";
import Pages from "./Pages/Pages";
import Home from "./Pages/Home";
import { useLayoutEffect } from "react";
import NotFound from "./NotFound";
import ScrollButton from "./Scroller";
import Admin from "./Admin";


function App() {
  const { pathname } = useLocation();
  useLayoutEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
  }, [pathname]);
  
  return (
    <>
      <Routes>
        <Route path='/admin' element={<Admin/>}/>
        <Route path="/" element={<Home />} />
        <Route path="/:uri" element={<Pages />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <ScrollButton />
    </>
  );
}

export default App;
