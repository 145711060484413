import React from "react";
import "./nodecard.scss";
import SanitizeHTML from "../../SanitizeHTML";
function Cards2(props) {
  const line = props.homeBuilder;
  return (
    <section className="border-cards py-lg-4">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <h2>{line.heading}</h2>
            <div className="d-flex animate justify-content-center ">
              <div className="blue-sm"></div>
              <div className="line-sm"></div>
            </div>
            <p
              className="paragraph"
              dangerouslySetInnerHTML={{
                __html: SanitizeHTML(line.descriptionsifAny),
              }}
            />
          </div>
          </div>
          <div className="row mt-lg-5 my-3 ">
            {line.createcards.slice(0, 4).map((el, index) => (
              <div className="col-lg-3 col-sm-6 mt-3" key={index}>
                  <div
                    className="card "
                    style={{ border: `3px solid ${el.border}` }}
                  >
                    {el.cardsimage?.node?.sourceUrl && (
                      <img
                        src={el.cardsimage.node.sourceUrl}
                        alt="Card Image"
                        className="img-fluid"
                      />
                    )}
                    <div className="card-body">
                      <h3>{el.cardshead}</h3>
                      <p
                        className="para"
                        dangerouslySetInnerHTML={{
                          __html: SanitizeHTML(el.cardsdescriptions),
                        }}
                      />
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="row my-lg-4" style={{ placeContent: "center" }}>
            {line.createcards.slice(4, 7).map((el, index) => (
              <div className="col-lg-3 col-sm-6 mt-3" key={index}>
                  <div
                    className="card "
                    style={{ border: `3px solid ${el.border}` }}
                  >
                    {el.cardsimage?.node?.sourceUrl && (
                      <img
                        src={el.cardsimage.node.sourceUrl}
                        alt="Card Image"
                        className="img-fluid"
                      />
                    )}
                    <div className="card-body">
                      <h3>{el.cardshead}</h3>
                      <p
                        className="para"
                        dangerouslySetInnerHTML={{
                          __html: SanitizeHTML(el.cardsdescriptions,)
                        }}
                      />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
    </section>
  );
}

export default Cards2;
