import { gql, useQuery } from "@apollo/client";
import React, { useEffect } from "react";
import "../App.scss";
import Intro from "./Home/Intro/Intro";
import HomeService from "./Home/Service/HomeService";
import { useParams } from "react-router-dom";
import Perk from "./Home/Perk/Perk";
import CardPage from "./Home/Cards/Cards";
import Client from "./Home/Clients/Client";
import Tech from "./Home/Tech/Tech";
import Portfolio from "./Home/Portfolio/Portfolio";
import Feedback from "./Home/Feedback/Feedback";
import Contact from "./ContactPage/Contact/Contact";
import Work from "./Home/Work/Work";
import Repeatcard from "./AboutPage/RepeaterCards/Repeatcard";
import Cardsabout from "./AboutPage/AboutCards/Cards";
import AboutMission from "./AboutPage/Mission/AboutMission";
import Layout from "./Layout";
import Banners from "./Banners/Banners";
import Navbars from "../Navbar";
import Foot from "./Footer/Foot";
import LoadMore from "./Portfolio/LoadMore";
import Contacts from "./ContactPage/ContactImg/Contacts";
import Demo from "./Gallery/Carousel/Demo";
import ImgCar from "./Gallery/ImageCarousel/ImgCar";
import Image from "./Gallery/Image";
import PicInPic from "./Gallery//PicInPic/PicInPic";
import ImgCont from "./Gallery/ImgContainer/ImgCont";
import ImgLeft from "./Gallery/ImgContainer/ImgLeft";
import Cards from "./Home/First/First";
import Line from "./Services/LineBorder/Line";
import ServiceCard from "./Services/Cards/ServiceCard";
import Service from "./Services/DigitalM/Service";
import Result from "./Services/Result/Result";
import Menu from "./Services/Menu/Menu";
import Seo from "../Seo";
import Videos from "./Videos/Video";
import Timeline from "./Services/Web/Timeline";
import WebService from "./Services/WevDev/WebServices/WebService";
import Webs from "./Services/WevDev/WebIdeal/Webs";
import BulletList from "./BulletList";
import WebDev from "./Services/WevDev/WebDevelop/WebDev";
import HomeBanner from "./Home/Banner/Banner";
import ReactDev from "./React-js/section-card/ReactDev";
import CardTable from "./React-js/CardTable/CardTable";
import Gridhex from "./React-js/HexagonGrid/Gridhex";
import Imgcon from "./React-js/Container/Imgcon";
import Cardsecond from "./React-js/Cardsrun/Cardsecond";
import Angularcard2 from "./Angular Js/Cards2/Angularcard2";
import Card1 from "./Angular Js/Card1/Card1";
import Cards3 from "./Angular Js/Cards3/Cards3";
import Cardline from "./Career/linecards/Cardline";
import Cards2 from "./Nodejs/Cards2/Cards2";
import Carouselcard from "./Nodejs/CarouselCard/Carouselcard";
import Imageontop from "./ImageonTop/Imageontop";
import Card from "./SimpleCard/Card";
import GridCircle from "./CircularcardGrid/GridCircle";
import Carousel from "./Gallery/Carousel/Carousel";
import NotFound from "../NotFound";

const GET_ABOUT = gql`
 query MyQuery2($uri: String!) {
  pageBy(uri: $uri) {
    homeBuilder {
      page {
        ... on HomeBuilderPageBannerLayout {
          selectLayoutsection
          stylingSection {
            backgroundPosition
            backgroundSize
            backgroundImage {
              node {
                sourceUrl
              }
            }
          }
          group {
            heading
            headerText
            button {
              title
              url
            }
            paragraph
            images {
              node {
                sourceUrl
              }
            }
          }
        }
        ... on HomeBuilderPagePerksSectionLayout {
          fieldGroupName
          bgColor
          buttonNameifAny {
            title
            url
          }
          layoutOfContainer
          column1 {
            image {
              node {
                sourceUrl
              }
            }
          }
          column2 {
            head
            descriptions
            anothercardsif {
              description
              head
            }
            columncards {
              cardshead
              cardsdescription
            }
          }
        }
        ... on HomeBuilderPageReviewcarouselLayout {
          heading
          description
          selectthecarousellayout
          review {
          border
            content
            name
            posititon
            images {
              node {
                sourceUrl
              }
            }
            clientimage {
              node {
                sourceUrl
              }
            }
          }
        }
        ... on HomeBuilderPageMenuListItemLayout {
          borderStyle
          fieldGroupName
          listItem {
            icon {
              node {
                sourceUrl
              }
            }
            listItem
          }
        }
        
        ... on HomeBuilderPageVideosLayout {
          bgColor
          fieldGroupName
          title
          videoHeight
          videoWidth
          videos {
            videoTitle
            videoDescriptions
            videoThumbnail {
              node {
                sourceUrl
              }
            }
            videoLink {
              node {
                sourceUrl
              }
            }
          }
        }
        ... on HomeBuilderPageGalleryCarouselLayout {
          fieldGroupName
          heading
          galleryImages {
            imageFive {
              node {
                sourceUrl
              }
            }
            imageFour {
              node {
                sourceUrl
              }
            }
            imageOne {
              node {
                sourceUrl
              }
            }
            imageSeven {
              node {
                sourceUrl
              }
            }
            imageSix {
              node {
                sourceUrl
              }
            }
            imageThree {
              node {
                sourceUrl
              }
            }
            imageTwo {
              node {
                sourceUrl
              }
            }
          }
        }
        ... on HomeBuilderPageContactformLayout {
          buttonname
          descriptions
          heading
          images {
            node {
              sourceUrl
            }
          }
          repeaterField {
            labels
          }
        }
        ... on HomeBuilderPageTimelineSectionLayout {
          heading
          text
          timelineColumns {
            descriptions
            head
            bulletPointColor
            arrowIcon {
              node {
                sourceUrl
              }
            }
          }
        }
        ... on HomeBuilderPageImageInImagesLayout {
          descriptions
          fieldGroupName
          heading
          imageOne {
            node {
              sourceUrl
            }
          }
          imageTwo {
            node {
              sourceUrl
            }
          }
        }
       
        ... on HomeBuilderPageTablecardLayout {
          fieldGroupName
          heading
          groupsofcards {
            card5head
            card3description
            card5description
            card4head
            card4description
            card3head
            card2description
            card1head
            card1description
            card2head
          }
        }
        ... on HomeBuilderPageContactpageLayout {
          contactInformation {
            text
            icons {
              node {
                sourceUrl
              }
            }
          }
          backgroundImages {
            node {
              sourceUrl
            }
          }
          embededgooglemapcode
          description
          heading
          form {
            anyheading
            buttonname
            contactformlabels {
              firstinput
              fiveinput
              fourthInput
              secondinput
              thirdinput
            }
          }
          contactinfo {
            address
            addresscountry
            adress2
            adress2country
            phonenumber
            gmail
            adress2phonenumber
            adress2gmail
          }
        }
        ... on HomeBuilderPageContainerLayout {
          bgColor
          imageposition
          row {
          textHead
           imageicon {
              images {
                node {
                  sourceUrl
                }
              }
            }
            buttonLink {
              title
              url
            }
            col1
            col1paragraph
            col2image {
              node {
                sourceUrl
              }
            }
          }
        }

        ... on HomeBuilderPageCardscolimageLayout {
        bulletListColor
        bgcolor
          selectComponentLayout
          rowcontainer {
            colimage {
              node {
                sourceUrl
              }
            }
            col2 {
              col2head
              coltext
              col2cards {
              borderColor
                cardstext
                cardsDescription
                cardsicon {
                  node {
                    sourceUrl
                  }
                }
              }
              button {
                title
                url
              }
            }
          }
        }
        ... on HomeBuilderPageRotatecontainerLayout {
        selectLayout
          button {
            title
            url
          }
          cardsColumn {
            border
            cardDescription
            cardheading
            cardicon {
              node {
                sourceUrl
              }
            }
          }
          buttontext {
            text
            buttonname {
              title
              url
            }
            descriptions
          }
          headingandtext {
            heading
            descriptions
            listitem {
              name
            }
          }
          cardsrotates {
            cardhead
            cards2description
            cards2head
            cards3description
            cards3head
            cards4description
            cardsdescription
            cards4head
            cardsimage {
              node {
                sourceUrl
              }
            }
            cards2image {
              node {
                sourceUrl
              }
            }
            cards3image {
              node {
                sourceUrl
              }
            }
            cards4image {
              node {
                sourceUrl
              }
            }
          }
        }
        ... on HomeBuilderPageImagesheadingLayout {
          heading
          select
          description
          backgroundColor
          imageicon {
            selectTheWorkGallerySection
            height
            widthinpx
            images {
              node {
                sourceUrl
              }
            }
          }
        }
        ... on HomeBuilderPageCircleimagetextLayout {
          heading
          firstfield {
            name
            iconpic {
              node {
                sourceUrl
              }
            }
          }
          secondfield {
            iconname
            iconimage {
              node {
                sourceUrl
              }
            }
          }
        }
        ... on HomeBuilderPageSimplecardsLayout {
          border
          borderRadius
          createcards {
           arrowIcon {
              node {
                sourceUrl
              }
            }
          circularColor
          vlColor
            border
            textHead
            cardsdescriptions
            cardshead
            cardsimage {
              node {
                sourceUrl
              }
            }
          }
          heading
          select
          backgroundColor
          descriptionsifAny
          marginTopinPx
          headingPosition
          paddinginPx
        }
        ... on HomeBuilderPageSeoLayout {
          titleOfPage
          metaTitlesAndDescriptions {
            metaDescriptions
            metaTitles
          }
        }
        ... on HomeBuilderPageFourCardsLayout {
          heading
          bgcolor
          ifSelect {
            buttonimg3 {
              node {
                sourceUrl
              }
            }
            buttonImg2 {
              node {
                sourceUrl
              }
            }
            button4 {
              node {
                sourceUrl
              }
            }
            button1 {
              node {
                sourceUrl
              }
            }
          }
          description
          selectLayoutStyle
          stylingCards {
            border2Card
            border3Card
            border4Card
            border
          }
          cards {
            card1name
            card1text
            card2name
            card2text
            card3name
            card3text
            card4name
            card4text
            card1img {
              node {
                sourceUrl
              }
            }
            card2img {
              node {
                sourceUrl
              }
            }
            card3img {
              node {
                sourceUrl
              }
            }
            card4img {
              node {
                sourceUrl
              }
            }
          }
        }
      }
    }
  }
}
`;
function Pages() {
  useEffect(() => {
    const handleMouseMove = (e) => {
      const cursor = document.getElementById("cursor");
      const dot = document.getElementById("dot");
      if (cursor) {
        cursor.style.left = e.clientX + "px";
        cursor.style.top = e.clientY + "px";
      }

      if (dot) {
        dot.style.left = e.clientX - dot.offsetWidth / 2 + "px";
        dot.style.top = e.clientY - dot.offsetHeight / 2 + "px";
      }
    };

    document.body.addEventListener("mousemove", handleMouseMove);

    return () => {
      document.body.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);
  const { uri } = useParams();
  const uriString = String(uri);
  const { loading, error, data } = useQuery(GET_ABOUT, {
    variables: { uri: uriString },
  });

  if (loading) {
    return (
      <div className=" spinner-container ">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  if (error || !data?.pageBy) {
    return <NotFound />;
  }

  const { pageBy } = data;
  const homeBuilders = pageBy.homeBuilder.page;

  return (
    <>
      <div className="app" id="cursor"></div>
      <div id="dot"></div>

      <Navbars />
      {homeBuilders.map((homeBuilder, index) => {
        if (homeBuilder.__typename === "HomeBuilderPageSeoLayout") {
          return (
            <>
              <Seo homeBuilder={homeBuilder} />
            </>
          );
        }
        if (homeBuilder.__typename === "HomeBuilderPageBannerLayout") {
          if (homeBuilder.selectLayoutsection === "Home") {
            return (
              <>
                <HomeBanner homeBuilder={homeBuilder} />
              </>
            );
          } else return <Banners homeBuilder={homeBuilder} />;
        } else if (
          homeBuilder.__typename === "HomeBuilderPageFourCardsLayout"
        ) {
          if (homeBuilder.selectLayoutStyle === "layout1") {
            return <Cards homeBuilder={homeBuilder} />;
          } 
          else if (homeBuilder.selectLayoutStyle === "layout2") {
            return <Cardsabout homeBuilder={homeBuilder} />;
          } 
          else if (homeBuilder.selectLayoutStyle === "layout3") {
            return <ServiceCard homeBuilder={homeBuilder} />;
          } else if (homeBuilder.selectLayoutStyle === "layout4") {
            return <Cards homeBuilder={homeBuilder} />;
          } else {
            return null;
          }
        } else if (
          homeBuilder.__typename === "HomeBuilderPageContainerLayout"
        ) {
          if (homeBuilder.imageposition=== "Right (Gallery Section)") {
          return (
            <>
              <ImgCont homeBuilder={homeBuilder} />
            </>
          )}
          else if (homeBuilder.imageposition === "Left") {
            return <ImgLeft homeBuilder={homeBuilder} />
          }
          else if (homeBuilder.imageposition === "Middle") {
            return <Image homeBuilder={homeBuilder}/>
          }
          else if (homeBuilder.Imageposition === "Right (Home Section)") {
            return <Intro homeBuilder={homeBuilder}/>
          }
          else if (homeBuilder.imageposition === "Left with Image icons") {
            return <Imgcon homeBuilder={homeBuilder}/>
          }
          }
         else if (
          homeBuilder.__typename === "HomeBuilderPageCardscolimageLayout"
        ) {
          if (homeBuilder.selectComponentLayout === "Home") {
            return (
              <>
                <HomeService homeBuilder={homeBuilder} />
              </>
            );
          } else if (homeBuilder.selectComponentLayout === "Service") {
            return <Service homeBuilder={homeBuilder} />;
          }
          else if (homeBuilder.selectComponentLayout === "Web Develop") {
            return <Webs homeBuilder={homeBuilder}/>
          }
          else if (homeBuilder.selectComponentLayout === "Bullet List Cards") {
            return <BulletList homeBuilder={homeBuilder}/>
          }
        } else if (
          homeBuilder.__typename === "HomeBuilderPagePerksSectionLayout"
        ) {
          return (
            <>
              <Perk homeBuilder={homeBuilder} />
            </>
          );
        } else if (
          homeBuilder.__typename === "HomeBuilderPageTablecardLayout"
        ) {
          return (
            <>
              <CardPage homeBuilder={homeBuilder} />
            </>
          );
        } else if (
          homeBuilder.__typename === "HomeBuilderPageRotatecontainerLayout"
        ) {
          if (homeBuilder.selectLayout === "Clients") {
            return (
              <>
                <Client homeBuilder={homeBuilder} />
              </>
            );
          } 
          else if (homeBuilder.selectLayout === "Mission") {
            return <AboutMission homeBuilder={homeBuilder} />;
          } 
          else return null;
        } else if (
          homeBuilder.__typename === "HomeBuilderPageCircleimagetextLayout"
        ) {
          return (
            <>
              <Tech homeBuilder={homeBuilder} />
            </>
          );
        } else if (
          homeBuilder.__typename === "HomeBuilderPageImagesheadingLayout"
        ) {
          if (homeBuilder.select === "Images In Box")
            return (
              <>
                <Work homeBuilder={homeBuilder} />
              </>
            );
          else if (homeBuilder.select === "Carousel Images") {
            return <Portfolio homeBuilder={homeBuilder} />;
          } else if (homeBuilder.select === "Load More Less") {
            return <LoadMore homeBuilder={homeBuilder} />;
          } else if (homeBuilder.select === "Gallery Carousel") {
            return <ImgCar homeBuilder={homeBuilder} />;
          }
          // else if (homeBuilder.select === "Gallery") {
          //   return <Demo homeBuilder={homeBuilder} />;
          // }
        } else if (
          homeBuilder.__typename === "HomeBuilderPageReviewcarouselLayout"
        ) {
          if (homeBuilder.selectthecarousellayout==="Reviews") {
            return (
              <>
                <Feedback homeBuilder={homeBuilder} />
              </>
            )}
            else if (homeBuilder.selectthecarousellayout==="Cards (With Border Right)"){
            return <Carouselcard homeBuilder={homeBuilder} />
            }
        } else if (
          homeBuilder.__typename === "HomeBuilderPageContactpageLayout"
        ) {
          return (
            <>
              <Contact homeBuilder={homeBuilder} />
            </>
          );
        } else if (
          homeBuilder.__typename === "HomeBuilderPageSimplecardsLayout"
        ) {
          if (homeBuilder.select === "Cards") {
            return <Repeatcard homeBuilder={homeBuilder} />;
          } 
          else if (homeBuilder.select== "Simple Cards") {
            return <Card homeBuilder={homeBuilder}/>
          }
          else if (homeBuilder.select === "LineBorder (for 3)") {
            return <Result homeBuilder={homeBuilder} />;
          } 
          else if (homeBuilder.select=== "LineBorder (for 6)") {
            return <Cardline homeBuilder={homeBuilder}/>
          }
          else if ( homeBuilder.select === "Carousel") { 
              return <WebService homeBuilder={homeBuilder}/>
            }
            else if ( homeBuilder.select === "Circular") { 
              return <WebDev homeBuilder={homeBuilder}/> 
            }
            else if (homeBuilder.select=== "Card Bullet List") {
              return <ReactDev homeBuilder={homeBuilder}/>
            }
             else if (homeBuilder.select=== "Hexagon Card") {
              return <Gridhex homeBuilder={homeBuilder}/>
            }
            else if (homeBuilder.select ==="Five Table Cards (with vl)") {
              return <CardTable homeBuilder={homeBuilder}/>
            }
            else if (homeBuilder.select ==="Cards before after") {
              return <Cardsecond homeBuilder={homeBuilder}/>
            }
            else if (homeBuilder.select === "Circle on top (three per row)") {
              return <Angularcard2 homeBuilder={homeBuilder}/>
            }
            else if (homeBuilder.select === "Image on side") {
              return <Card1 homeBuilder={homeBuilder}/>
            }
            else if (homeBuilder.select==="4 Cards(Image on Top)") {
              return <Imageontop homeBuilder={homeBuilder}/>
            }
            else if (homeBuilder.select === "Line Border") {
              return <Cards3 homeBuilder={homeBuilder}/>
            }
            else if (homeBuilder.select === "Cards (center)") {
              return <Cards2 homeBuilder={homeBuilder}/> 
            }
            else if (homeBuilder.select === "Circular Card Grid") {
              return <GridCircle homeBuilder={homeBuilder}/>
            }
           else return null;
        } 
        
        else if (
          homeBuilder.__typename === "HomeBuilderPageContactformLayout"
        ) {
          return <Contacts homeBuilder={homeBuilder} />;
        }
        else if (
          homeBuilder.__typename === "HomeBuilderPageGalleryCarouselLayout"
        ) {
          return <Carousel homeBuilder={homeBuilder} />;
        }
        else if (
          homeBuilder.__typename === "HomeBuilderPageImageInImagesLayout"
        ) {
          return <PicInPic homeBuilder={homeBuilder} />;
        }
         else if (homeBuilder.__typename === "HomeBuilderPageVideosLayout") {
          return <Videos homeBuilder={homeBuilder} />;
        } 
        
        else if (
          homeBuilder.__typename === "HomeBuilderPageMenuListItemLayout"
        ) {
          if (homeBuilder.borderStyle === "Solid") {
            return <Menu homeBuilder={homeBuilder} />;
          }
        } 
        else if (homeBuilder.__typename==="HomeBuilderPageTimelineSectionLayout") {
          return <Timeline homeBuilder={homeBuilder}/>
        }
        else {
          return null;
        }
      })}
      <Foot />
    </>
  );
}

export default Pages;
