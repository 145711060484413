import React from "react";
import SanitizeHTML from "../SanitizeHTML";
import './Cards.scss'
function Card(props) {
  const simplecard = props.homeBuilder;
  return (
    <section className="simple-card" style ={{backgroundColor:simplecard.backgroundColor}}>
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <h2 dangerouslySetInnerHTML={{__html:simplecard.heading}} />
            
            <div className="d-flex animate justify-content-center">
              <div className="blue-sm"></div>
              <div className="line-sm"></div>
            </div>
            <p
              dangerouslySetInnerHTML={{
                __html: SanitizeHTML(simplecard.descriptionsifAny),
              }}
              className="paragraph"
            />
          </div>
        </div>
        <div className="row my-lg-5 my-3">
        {simplecard.createcards.map((el,index)=>(
            <div className="col-lg-4 col-sm-6 mt-lg-4 mt-xxl-5 mt-4" key={index}>
                <div className="card text-center" style={{border:`8px solid ${el.border}`,
            borderRadius:'50px'}} >
                {el.cardsimage?.node?.sourceUrl && (
                    <img src={`${el.cardsimage.node.sourceUrl}`} className="img-fluid"
                    alt="card-image"/>
                )}
                <div className="card-body">
                    <h3>{el.cardshead}</h3>
                    <p className="para" dangerouslySetInnerHTML={{__html:el.cardsdescriptions}} />
                </div>
                    </div>
                </div>
        ))}
      </div>
      </div>
    </section>
  );
}

export default Card;
