import { gql, useQuery } from "@apollo/client";
import React from "react";
import { NavLink } from "react-router-dom";
import waves from "../../images/Vector.png";
import "./Footer.scss";
import DOMPurify from "dompurify";
const GET_FOOTER = gql`
  query MyQuery2 {
    pageBy(uri: "/footer") {
      homeBuilder {
        page {
          ... on HomeBuilderPageFooterpageLayout {
            fieldGroupName
            descriptions {
              text
              icon {
                node {
                  sourceUrl
                }
              }
            }
            address {
              address
            }
            listitems {
              heading
              item1 {
                item {
                  url
                  title
                }
              }
            }
            iconsOnTop {
              icons {
                node {
                  sourceUrl
                }
              }
            }
               socialMediaIcons {
            icons {
              node {
                sourceUrl
              }
            }
          }
            iconOnBottom {
              icons {
                node {
                  sourceUrl
                }
              }
            }
          }
        }
      }
    }
  }
`;
function Foot() {
  const { loading, error, data } = useQuery(GET_FOOTER);
  if (loading) {
    return <p></p>;
  }
  if (error) {
    <p>error:{error.message}</p>;
  }
  const { pageBy } = data;
  const homeBuilders = pageBy.homeBuilder.page;
  const sanitizeHTML = (homeBuilders) => {
    let cleanHTML = DOMPurify.sanitize(homeBuilders);
    return cleanHTML.replace(/<\/p>|<p>|<\/p>$/g, "").replace(/^<p>$/g, "");
  };
  return (
    <>
      {homeBuilders.map((homeBuilder, index) => (
        <section
          key={index}
          className="footer"
          style={{
            backgroundImage: `url(${waves})`,
            backgroundSize: "cover",
            backgroundPosition: "top",
          }}
        >
          <div className="container ">
              {homeBuilder.iconsOnTop.map(
                (el, index) =>
                  index === 0 && (
                   
                      <img
                        src={el.icons.node.sourceUrl}
                        className="img-fluid icon-image"
                        alt="icons"
                      />
                  )
              )}
<div className="row">
              <div className="col-lg-4 col-sm-6 col-md-6">
                <img
                  src={homeBuilder.descriptions.icon.node.sourceUrl}
                  className="img-fluid banner-image"
                  alt="icon"
                />
               {homeBuilder.iconsOnTop.map(
                (el, index) =>
                  index === 1 && (
                   
                      <img
                        src={el.icons.node.sourceUrl}
                        className=" icon-image "
                        alt="icons"
                      />
                  )
              )}
                <p
                  className="para"
                  dangerouslySetInnerHTML={{
                    __html: sanitizeHTML(homeBuilder.descriptions.text),
                  }}
                />
              </div>
        
              {/* {homeBuilder.listitems.map((el, index) => (
                <div className=" col-lg-2 ms-sm-auto col-6 " key={index.id}>
                  <h3 className="my-3 ">{el.heading}</h3>
                  {homeBuilder.iconsOnTop.map(
                (el, index) =>
                  index === 3 && (
                   
                      <img
                        src={el.icons.node.sourceUrl}
                        className="img-fluid icon-image"
                        alt="icons"
                      />
                  )
              )}
                  <ul className="list-unstyled ">
                    {el.item1.map((el, index) => (
                      <li className="py-1" key={index.id}>
                        <NavLink
                          to={el.item.url}
                          style={{ textDecoration: "none", color: "black" }}
                        >
                          {el.item.title}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                </div>
              ))} */}
 {homeBuilder.listitems.slice(0, 2).map((el, index) => (
      <React.Fragment key={index}>
        <div className="col-lg-2 ms-sm-auto col-sm-6 col-md-6">
          <div className="d-flex">
          <h3 className="my-3">{el.heading}</h3>
          {index === 0 && (
         
         <img
           src={homeBuilder.iconsOnTop[2].icons.node.sourceUrl}
           className=" icon-image"
           alt="icons"
         />
     )}
     </div>
          <ul className="list-unstyled">
            {el.item1.map((item, itemIndex) => (
              <li className="py-1" key={itemIndex}>
                <NavLink
                  to={item.item.url}
                  style={{ textDecoration: "none", color: "black" }}
                >
                  {item.item.title}
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
        
      </React.Fragment>
    ))}
              <div className="col-lg-3 ms-auto col-sm-6 col-md-6">
                <div className="d-flex">
                <h3 className="my-3  ">Our Offices</h3>
                {homeBuilder.iconsOnTop.map(
                (el, index) =>
                  index === 3 && (
                      <img
                        src={el.icons.node.sourceUrl}
                        className=" icon-image "
                        alt="icons"
                      />
                  )
              )}
              </div>
                <ul className="list-unstyled ">
                  {homeBuilder.address.map((el, index) => (
                    <>
                    <p
                      className="address"
                      key={index.id}
                      dangerouslySetInnerHTML={{
                        __html: sanitizeHTML(el.address),
                      }}
                    />
                    <br/>
                    </>
                  ))}
                </ul>
              </div>
            </div>
            <div className="row">
              {homeBuilder.iconOnBottom.map((el, index) => (
                <div
                  className={`col-3 ${
                    index !== 0 ? "d-flex  justify-content-end" : ""
                  }`}
                  key={index}
                >
                  <img
                    src={el.icons.node.sourceUrl}
                    className=" icon-images"
                    alt="icons"
                  />
                </div>
              ))}
            </div>
            <div className="row py-3">
              <hr />
              <div className="d-flex justify-content-between">
                <p className="para" >
                  CopyRights &copy; 2024, All Rights Reserved With Avaptech
                </p>
                <div className="d-flex">
                  {homeBuilder.socialMediaIcons.map((el,index) => (
                    <NavLink to="/" className="rounded pe-2" key={index}>
                    <img src={el.icons.node.sourceUrl} className="img-fluid"/>
                  </NavLink>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      ))}
    </>
  );
}

export default Foot;
