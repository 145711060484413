import React from "react";
import './Cont.scss'
import DOMPurify from "dompurify";
function Contacts(props) {
  let con = props.homeBuilder;
  const sanitizeHTML = (homeBuilders) => {
    let cleanHTML = DOMPurify.sanitize(homeBuilders);
     return cleanHTML.replace(/<\/p>|<p>|<\/p>$/g, "").replace(/^<p>$/g, "");
  };
  return (
    <section className="career-contact">
      <div className="container ">

        <div className="row ">
          <div className="col-lg-6 ">
          <h2 className="text-start" dangerouslySetInnerHTML={{__html:con.heading}}/>
          <div className="d-flex animate justify-content-start ">
              <div className="blue"></div>
              <div className="line"></div>
            </div>
        <p className="pb-5 paragraph" dangerouslySetInnerHTML={{__html:sanitizeHTML(con.descriptions)}}/>
            <form >
              <p className="pb-2 paragraph">Fields marked with an * are required</p>
              {con.repeaterField.map((el,index)=>(
                <div className=" pt-1 mb-3 col-12" key={index}>
                  <input
                    name={`${el.labels}`}
                    type="text"
                    placeholder={`${el.labels}`}
                    className="form-control my-1 para"
                    required
                  ></input>
                </div>
            ))  }
              <div className="col-6  pt-4">
                <button type="submit" className="btn btn1">
                  {con.buttonname}
                </button>
              </div>
            </form>
          </div>
          <div className="col-lg-6 ">
            <img src={`${con.images.node.sourceUrl}`} className="img-fluid cont-image" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contacts;
