import React from "react";
import "./Contact.scss";
import ContactForm from "./ContactForm";

function Contact(props) {
  let contact = props.homeBuilder;
  return (
    <section className="contact" id="contact">
      <div className="container my-4">
        <div className="row">
          <div className="col-lg-6">
            <ContactForm contact={contact} />
          </div>
          <div className="col-lg-1 ">
            <hr className="hr" />
            <span className="vl" />
          </div>
          <div className="col-lg-5 auto align-content-center mt-5 mb-5" style={{backgroundImage:`url(${contact.backgroundImages.node.sourceUrl})`}}>
            <h2>{contact.form.anyheading}</h2>

            <div className="row">
             <ul className="list-unstyled">
              {contact.contactInformation.map((el,index)=>(
                   <li className="d-flex" key={index}>
                   {el.icons?.node?.sourceUrl ? (
                     <>
                       <img src={el.icons.node.sourceUrl} className="img-fluid contact-icons"  alt="contact-icons" />
                       <p className="paragraph" dangerouslySetInnerHTML={{__html:el.text}} />
                     </>
                   ) : (
                     <h3 className="mt-4">{el.text}</h3>
                   )}
                 </li>
              ))}
             </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-4 mb-5">
        <div className="row ">
          <div className="col-12">
          <iframe title="map"
            src={`${contact.embededgooglemapcode}`}
            style={{
              borderRadius: "10px",
              width: "100%",
              margin:'0 auto',
              height: "450px",
              boxShadow: "10px 10px 17.100000381469727px 0px #00000040",
            }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
