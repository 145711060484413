import React, { useEffect, useState } from "react";
import "./Work.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
function Work(props) {
  const work = props.homeBuilder;
  const [smallScreem, setSmallScreen] = useState(window.innerWidth < 1280);
  useEffect(() => {
    const handleResize = () => {
      setSmallScreen(window.innerWidth < 1280);
    };
    window.AOS.init();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      <section
        className="work colorblue"
        style={{ background: work.backgroundColor }}
      >
        <div className="container py-3">
          <h2 className="text-center">{work.heading}</h2>
          <div className="d-flex animate justify-content-center">
            <div className="blue-lg"></div>
            <div className="line-lg"></div>
          </div>
          {smallScreem ? (
              <Swiper
                className="mySwiper"
                modules={[Pagination]}
                pagination={{ clickable: true }}
                breakpoints={{
                  375: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                  },
                  549: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                  },
                  982: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                  },
                }}
              >
                {work.imageicon.map((el) => (
                  <SwiperSlide key={el.id}>
                    <div className="card align-items-center">
                      <img
                        src={el.images.node.sourceUrl}
                        className="card-img-top"
                        style={{
                          height: `${el.height}px`,
                          width: `${el.widthinpx}px`,
                        }}
                      />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
          ) : (
              <div className="row images-box">
                {work.imageicon.map((el) => (
                  <div className="col-lg-3 col-md-6 col-sm-6 mt-5">
                    <div className="card align-items-center">
                      <img
                        src={el.images.node.sourceUrl}
                        className="card-img-top  "
                        style={{
                         height: `${el.height}px`,
                          width: `${el.widthinpx}px`,
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
          )}
        </div>
      </section>
    </>
  );
}

export default Work;
