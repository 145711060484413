import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "./Webservice.scss";
import {  Pagination } from "swiper/modules";
import SanitizeHTML from '../../../SanitizeHTML';
function WebService(props) {
let web= props.homeBuilder;


  return (
    <section className='web-service'>
      <div className='container' style={{'--border-radius' :web.borderRadius}}>
        <div className='row my-5'>
           <h2 className='text-center ' >
            {web.heading}
           </h2>
           <div className="d-flex animate justify-content-center ">
              <div className="blues"></div>
              <div className="lines"></div>
            </div>
            <p className='text-center paragraph' 
            dangerouslySetInnerHTML={{__html:SanitizeHTML(web.descriptionsifAny)}} />
                <Swiper
              className="mySwiper"
              modules={[Pagination]}
              loop={true}
              pagination={{ clickable: true }}
              breakpoints={{
                500: {
                  slidesPerView: 1,

                },

                720: {
                  slidesPerView: 2,
                  spaceBetween: 72,
                },
                1023: {
                  slidesPerView: 2.4,
                  spaceBetween: 10,
                },
                1200 :{
                  slidesPerView: 3,
                  spaceBetween: 18,
                },
                1400: {
                  slidesPerView: 3,
                  spaceBetween: 0,
                },
                1600:{
                    slidesPerView:3.3,
                    spaceBetween:70,
                },
                1920:{
                  slidesPerView:3.5,
                  spaceBetween:10,
              },
                2000: {
                  slidesPerView: 4,
                  spaceBetween: 70,
                },
              }}
            >
              {web.createcards.map((el, index) => ( 
                <SwiperSlide className="pb-3 ms-lg-3  ms-xl-4" key={index}>
                <div className="card  ms-3 my-5 imgtxt">
                <h3 className ="card-number" style={{color:el.border}}>{el.textHead}</h3>
                <div className="card-body" style={{"--border":`30px solid ${el.border}`}}>
                  <div className="px-lg-4 px-md-3 px-sm-2">
                  <h3 className='card-head' style={{marginTop:'-3rem'}}>{el.cardshead}</h3>
                  <p className="para mt-3" dangerouslySetInnerHTML={{__html: SanitizeHTML(el.cardsdescriptions)}}/>
                  </div>
                  </div>
                </div>
                </SwiperSlide>
              ))}
               

            </Swiper>
          </div>
      </div>
    </section>
  )
}

export default WebService

