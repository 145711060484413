import React from "react";
import "./Cardline.scss";
import SanitizeHTML from "../../SanitizeHTML";
function Cardline(props) {
  const lineborder = props.homeBuilder;
  return (
    <section className="line-border">
      <div className="container">
        <div className="row">
          <div className={`col-12 text-${lineborder.headingPosition}`}>
            <h2 dangerouslySetInnerHTML={{__html: lineborder.heading}}/>
            <div className={`d-flex animate justify-content-${lineborder.headingPosition}`}>
              <div className="blue-sm"></div>
              <div className="line-sm"></div>
            </div>
            {lineborder.descriptionsifAny && (
              <p className="paragraph" dangerouslySetInnerHTML={{__html:SanitizeHTML(lineborder.descriptionsifAny)}}/>
            )}
            
          </div>
          </div>
          <div className="container line-cards mt-3">
          <div className="row ">
            {lineborder.createcards.map((el,index)=>(
                <div  className={`col-lg-4 col-sm-6 ${index !== 2 && index !== 5 ? "moves" : ""}`} key={index}>
                <div className="card">
                  <div className="card-body">
                    <h3>{el.cardshead}</h3>
                  
                    <p
                      className="card-text paragraph"
                      dangerouslySetInnerHTML={{
                        __html: SanitizeHTML(el.cardsdescriptions),
                      }}
                    />
                  </div>
                </div>
            <div className="vertical"></div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Cardline;
