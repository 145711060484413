// src/components/NotFound.js
import React from 'react';
import Navbars from './Navbar';
import Foot from './Pages/Footer/Foot';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { NavLink } from 'react-router-dom';
import image from './images/error.png'
const NotFound = () => {
  return (
    <div>
      <HelmetProvider>
    <Helmet>
         <link rel="icon" href=".././favicon.png" />
        <title>OOPs... Page not found</title>
            <meta name="description" content="The page you are looking for does not exist."/>
    </Helmet>
    </HelmetProvider>
      <Navbars />
      <div className="text-center not-found my-5 d-flex flex-column justify-content-center align-items-center ">
          <h1 className='text-primary my-4 '>OOPSS... </h1>
          <h3>
		Look like you're lost
          </h3>
      <img src={`${image}`} loading="lazy" alt="error-img" className="img-fluid" />
        
      <p className='paragraph'>The page you are looking for does not exist.</p>
<button className='btn btn-primary'>
  <NavLink to="/" className="error-404 navlink">GO TO HOME</NavLink>
</button>
      </div>
<Foot/>
    </div>

  );
};

export default NotFound;
