import React from "react";
import "./Webs.scss";
import DOMPurify from "dompurify";
function Webs(props) {
  const webs = props.homeBuilder;
  const sanitizeHTML = (homeBuilders) => {
    const cleanHTML = DOMPurify.sanitize(homeBuilders);
    return cleanHTML.replace(/<\/p>|<p>|<\/p>$/g, "").replace(/^<p>$/g, "");
  };
  return (
    <section className="web-ideal" style={{ backgroundColor: webs.bgcolor }}>
      <div className="container">
        <div className="row flex">
          <div className="col-lg-7 my-lg-5">
            <h2>{webs.rowcontainer.col2.col2head}</h2>
            <div className="d-flex animate justify-content-start ">
              <div className="blue"></div>
              <div className="line"></div>
            </div>
            <p className="paragraph"
              dangerouslySetInnerHTML={{
                __html: sanitizeHTML(webs.rowcontainer.col2.coltext),
              }}
            />

            <div className="container-fluid  my-xl-4">
              <div className="position mt-md-3 mt-lg-0">
                <div className="row">
                  {webs.rowcontainer.col2.col2cards.map((el) => (
                    <div className="col-lg-6 col-sm-6">
                      <div
                        className="card"
                        style={{ borderLeft: `6px solid ${el.borderColor}` }}
                      >
                        <div className="card-body">
                          <h3>{el.cardstext}</h3>
                          <p
                            className="para"
                            dangerouslySetInnerHTML={{
                              __html: sanitizeHTML(el.cardsDescription),
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5 ">
            <img
                src={webs.rowcontainer.colimage.node.sourceUrl}
                className="img-fluid col"
              />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Webs;
