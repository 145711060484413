import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "./Mb.scss";
import { FreeMode, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import DOMPurify from "dompurify";

export default function ImgCar(props) {
  const carousel= props.homeBuilder;
  const sanitizeHTML = (homeBuilders) => {
    const cleanHTML = DOMPurify.sanitize(homeBuilders);
    return cleanHTML.replace(/<\/p>|<p>|<\/p>$/g, "").replace(/^<p>$/g, "");
  };
  return (
    <section className="carousels py-lg-5">
    <div className="container">
    <div className="row text-center ">
      <div className="col-12 mt-5 mt-lg-0">
      <h2 dangerouslySetInnerHTML={{__html:carousel.heading}}/>
      <div className="d-flex animate justify-content-center ">
  <div className="blue-xs"></div>
  <div className="line-xs"></div>
</div>
      <p className="paragraph" dangerouslySetInnerHTML={{__html:sanitizeHTML(carousel.description)}}/>
    </div>
    </div>
    </div>
    <div className="container-fluid">
      <Swiper
       
        breakpoints={{
          320: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
          640: {
            slidesPerView: 1.5,
            spaceBetween: 20,
          },
      
          1500: {
            slidesPerView: 2.5,
            spaceBetween: 20,
          },
          1800 :{
            slidesPerView: 3,
            spaceBetween :25,
          },
          2500 : {
            slidesPerView: 3,
            spaceBetween: 15,
          }
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination , FreeMode]}
        className="mySwiper  mb-5"
      >
  <div className="mb-5">
        {carousel.imageicon.map((el,index)=>(
  <SwiperSlide
    className="mb-5"
  key={index} >
    <img
      src={`${el.images.node.sourceUrl}`}
      className="img-fluid"
    />
  </SwiperSlide>
        ))}
      
    
        </div>
      </Swiper>
      </div>

    </section>
  );
}
