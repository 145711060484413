import React from "react";
import "./Video.scss";
import DOMPurify from "dompurify";
function Videos(props) {
  const video = props.homeBuilder;
  const sanitizeHTML = (homeBuilders) => {
    const cleanHTML = DOMPurify.sanitize(homeBuilders);
    return cleanHTML.replace(/<\/p>|<p>|<\/p>$/g, "").replace(/^<p>$/g, "");
  };
  return (
    <>
      <section className="video-section my-lg-5 my-md-3">
        <div className="container">
          <h2 className="text-center">{video.title}</h2>
          <div className="d-flex animate justify-content-center">
            <div className="blue-xs"></div>
            <div className="line-xs"></div>
          </div>
          <div className="row">
            {video.videos.map((el, index) => (
              <div className="col-lg-6 my-4" key={index}>
                <video
                  poster={`${el.videoThumbnail.node.sourceUrl}`}
                  className="videos"
                  controls
                >
                  <source src="..." type="video/mp4"></source>
                </video>
                <h3 className="mt-xl-4 mt-lg-3">{el.videoTitle}</h3>
                <p
                  className="para py-2"
                  dangerouslySetInnerHTML={{
                    __html: sanitizeHTML(el.videoDescriptions),
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default Videos;
