import React from "react";
import "./Banners.scss";
import { NavLink } from "react-router-dom";
import DOMPurify from "dompurify";
function Banners(props) {
  let homeBuilders = props.homeBuilder;
  const sanitizeHTML = (homeBuilders) => {
    const cleanHTML = DOMPurify.sanitize(homeBuilders);
    return cleanHTML.replace(/^<p>|<\/p>$/g, "");
  };
  return (
    <>
      <section
        className="head1"
        style={{
          backgroundImage: `url(${homeBuilders.stylingSection.backgroundImage.node.sourceUrl})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: `${homeBuilders.stylingSection.backgroundPosition}`,
          backgroundSize: `${homeBuilders.stylingSection.backgroundSize}`,
        }}
      >
        <div className="container pb-4">
          <div className="row">
            <div className="col-lg-6 banners">
              {homeBuilders.group.headerText && (
                <div className="d-flex justify-content-start">
                  <p className="para"
                    dangerouslySetInnerHTML={{
                      __html: sanitizeHTML(homeBuilders.group.headerText),
                    }}
                  />
                  <hr className="hr" />
                </div>
              )}
              <h1
                dangerouslySetInnerHTML={{
                  __html: homeBuilders.group.heading,
                }}
              />
              <p
                className="paragraph"
                dangerouslySetInnerHTML={{
                  __html: sanitizeHTML(homeBuilders.group.paragraph),
                }}
              />
              {homeBuilders.group.button && homeBuilders.group.button.url && (
                <button className="btn btns">
                  <NavLink
                    className="navlink"
                    to={`${homeBuilders.group.button.url}`}
                  >
                    {homeBuilders.group.button.title}
                  </NavLink>
                </button>
              )}
            </div>
            <div className="col-lg-6 two">
              <img
                src={homeBuilders.group.images.node.sourceUrl}
                className="img-fluid banner-img"
                alt="Image"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Banners;
