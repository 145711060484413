import React, { useEffect, useState } from "react";
import "./Grid.scss";
import SanitizeHTML from "../../SanitizeHTML";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
function Gridhex(props) {
  const hexgrid = props.homeBuilder;
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    window.AOS.init();
    window.AOS.refresh();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section className="hex-grid">
      <div class="container">
        <div className="row text-center">
          <div className="col-12 mb-lg-3 mb-xl-4">
            <h2 dangerouslySetInnerHTML={{ __html: hexgrid.heading }} />
            <div className="d-flex animate justify-content-center">
              <div className="blue"></div>
              <div className="line"></div>
            </div>
            {hexgrid.descriptionsifAny && (
              <p
                dangerouslySetInnerHTML={{
                  __html: SanitizeHTML(hexgrid.descriptionsifAny),
                }}
                className="paragraph mx-lg-4 mx-xl-5"
              />
            )}
          </div>
        </div>
        {isSmallScreen ? (
          <>
            <Swiper
              className="mySwiper"
              modules={[Pagination]}
              loop={true}
              pagination={{ clickable: true }}
              breakpoints={{
                375: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                549: {
                  slidesPerView: 1.5,
                  spaceBetween: 15,
                },
                767: {
                  slidesPerView: 2,
                  spaceBetween: 10,
                }
              }}
            >
              {hexgrid.createcards.map((card) => (
                <SwiperSlide key={card.id}>
                  <div
                    class="hex"
                    style={{ backgroundColor: card.border }}
                  >
                    <div className="hex-background">
                      <div className="text-center center">
                        <h3>{card.cardshead}</h3>
                        <p
                          className="para"
                          dangerouslySetInnerHTML={{
                            __html: SanitizeHTML(card.cardsdescriptions),
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </>
        ) : (
          <>
            <div class="even">
              {hexgrid.createcards.slice(0, 2).map((card) => (
                <div
                  class="hex"
                  key={card.id}
                  style={{ backgroundColor: card.border }}
                >
                  <div className="hex-background">
                    <div className="text-center center">
                      <h3>{card.cardshead}</h3>
                      <p
                        className="para"
                        dangerouslySetInnerHTML={{
                          __html: SanitizeHTML(card.cardsdescriptions),
                        }}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div class="odd">
              {hexgrid.createcards.slice(2, 5).map((card) => (
                <div
                  class="hex"
                  key={card.id}
                  style={{ backgroundColor: card.border }}
                >
                  <div className="hex-background">
                    <div className="text-center center">
                      <h3>{card.cardshead}</h3>
                      <p
                        className="para"
                        dangerouslySetInnerHTML={{
                          __html: SanitizeHTML(card.cardsdescriptions),
                        }}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div class="even">
              {hexgrid.createcards.slice(5).map((card) => (
                <div
                  class="hex"
                  key={card.id}
                  style={{ backgroundColor: card.border }}
                >
                  <div className="hex-background">
                    <div className="text-center center">
                      <h3>{card.cardshead}</h3>
                      <p
                        className="para"
                        dangerouslySetInnerHTML={{
                          __html: SanitizeHTML(card.cardsdescriptions),
                        }}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </section>
  );
}

export default Gridhex;
