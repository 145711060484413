import React, { useState } from "react";
import "./Intro.scss";
import { NavLink } from "react-router-dom";
import DOMPurify from "dompurify";
function Intro(props) {
  const [modalOpen , setmodalOpen]= useState(false);
  const [modSrc, SetmodSrc]= useState ("");
  const [zoomedIn, setZoomedIn] = useState(false);
  const intro = props.homeBuilder;
  const sanitizeHTML = (homeBuilders) => {
    let cleanHTML = DOMPurify.sanitize(homeBuilders);
     return cleanHTML.replace(/<\/p>|<p>|<\/p>$/g, "").replace(/^<p>$/g, "");
  };
const toggleModal =(src)=>{
  setmodalOpen(true);
SetmodSrc(src)
};
const CloseModal =()=>{
  setmodalOpen(false);
  SetmodSrc("");
}
const handleImageDoubleClick = (src) => {
  if (zoomedIn) {
    // If already zoomed in, toggle back to normal
    setZoomedIn(false);
  } else {
    // If not zoomed in, toggle to zoomed in
    toggleModal(src);
    setZoomedIn(true);
  }
};
  return (
    <div>
      <section className="main py-lg-5 py-md-4" style={{ backgroundColor: `${intro.bgColor}` }}>
        <div className="container">
          <div className={`row `}>
            <div className="col-lg-7 col-md-12 col-sm-12 m-t">
              <h2>{intro.row.col1}</h2>
              <div className="d-flex animate justify-content-start ">
                <div className="blue"></div>
                <div className="line"></div>
              </div>
              <p
              className="paragraph"
                dangerouslySetInnerHTML={{ __html: sanitizeHTML(intro.row.col1paragraph) }}
              />
              {intro.row.buttonLink && intro.row.buttonLink.url && (
                <button className="btn btns">
                  <NavLink
                    className="navlink"
                    to={`${intro.row.buttonLink.url}`}
                  >
                    {intro.row.buttonLink.title}
                  </NavLink>
                </button>
              )}
            </div>
            <div className="col-lg-5 col-md-12  two col-sm-12">
              <img
              loading="lazy"
                src={intro.row.col2image.node.sourceUrl}
                className="img-fluid"
                onClick={() => handleImageDoubleClick(intro.row.col2image.node.sourceUrl)}
                alt="Zoomable-image"
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
        </div>
      </section>
      {modalOpen && (
        <div className="modal-overlay" onClick={CloseModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button onClick={CloseModal} className="close-button">×</button>
            <img src={modSrc} className='img-fluid 'alt="Zoomed" />
          </div>
        </div>
      )}
    </div>
  );
}

export default Intro;
