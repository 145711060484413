import DOMPurify from "dompurify";
import React, { useEffect } from "react";
import './Web.scss'
import { NavLink } from "react-router-dom";
function Timeline(props) {
  const timeline = props.homeBuilder;
  const sanitizeHTML = (homeBuilders) => {
    const cleanHTML = DOMPurify.sanitize(homeBuilders);
    return cleanHTML.replace(/^<p>|<\/p>$/g, "");
  };
  useEffect (()=>{
    window.AOS.init(
      {once:true}
    );

  },[]);
  return (
    <section className="timeline ">
      <div className="container">
        <div className="row text-center pt-5">
          <div className="col-12">
          <h2>{timeline.heading}</h2>
          <div className="d-flex animate justify-content-center">
            <div className="blues"></div>
            <div className="lines"></div>
          </div>
          <p
            className="paragraph"
            dangerouslySetInnerHTML={{
              __html: sanitizeHTML(timeline.text),
            }}
          />
        </div>
        </div>
        <div className="container" style={{ margin:'3rem 0' , position:'relative'}}>
          <div className="row middle-vl">
            {timeline.timelineColumns.map((el,index)=>(
              <>
              <div className='col-lg-6 col-md-6'>
{index % 2 === 1 && 
  <div className="d-flex justify-content-around left" >
              <div className="text"  data-aos="fade-right"
     data-aos-offset="200"
     data-aos-duration="500">
                <div className="circle" style={{backgroundColor: el.bulletPointColor}} />
                <h3>{el.head}</h3>
                <p className="para" dangerouslySetInnerHTML={{__html:sanitizeHTML(el.descriptions)}}/>
                <NavLink className="nav-link">View Details</NavLink>
              </div>
              <img src={`${el.arrowIcon.node.sourceUrl}`} className="img-fluid img-one" />
            </div>
}
</div>
<div className='col-lg-6 col-md-6'>

{index % 2 === 0 && 
  <div className="d-flex justify-content-around">
              <img src={`${el.arrowIcon.node.sourceUrl}`} className="img-fluid img-two" />
              <div data-aos="fade-left"
     data-aos-offset="200"
     data-aos-duration="500">
                <div className="circle" style={{backgroundColor: el.bulletPointColor}}/>
                <h3>{el.head}</h3>
                <p className="para" dangerouslySetInnerHTML={{__html:sanitizeHTML(el.descriptions)}}/>
                <NavLink className="nav-link">View Details</NavLink>
              </div>
            </div>

}
</div>
              </>
            ))}
            {/* <div className="col-lg-6 col-md-6">
              {timeline.leftTimelineSection.map((el, index) => (
                <div className="d-flex justify-content-around left" key={index}>
                  <div className="content"> 
                    <div
                      className="circle"
                      style={{ backgroundColor: el.bulletListColor }}
                    />
                    <h3>{el.heading}</h3>
                    <p
                      className="para"
                      dangerouslySetInnerHTML={{
                        __html: sanitizeHTML(el.descriptions),
                      }}
                    />
                    <NavLink className="text-decoration-none text-black">View Details</NavLink>
                  </div>

                  <img
                    src={`${el.imageicons.node.mediaItemUrl}`}
                    className="img-fluid img-one"
                  />
                </div>
              ))}
            </div>
            <div className="col-lg-6 col-md-6">
              {timeline.rightTimelineSection.map ((el,index)=>(
                <div className="d-flex justify-content-around" key={index}>
                <img src={`${el.imagesicon.node.sourceUrl}`} className="img-fluid img-two" />
                <div>
                  <div className="circle" style={{backgroundColor: el.bulletListColor}}/>
                  <h3>{el.heading}</h3>
                  <p className="para" dangerouslySetInnerHTML={{__html: sanitizeHTML(el.descriptions,)}} />
                  <NavLink className="text-decoration-none text-black">View Details</NavLink>

                </div>
              </div>
              ))}
            </div> */}
        </div>
        </div>
      </div>
    </section>
  );
}

export default Timeline;
