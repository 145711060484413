import React from "react";
import "./Card.scss";
import DOMPurify from "dompurify";
function Cardsabout(props) {
  let aboutcard = props.homeBuilder;
  const sanitizeHTML = (homeBuilders) => {
    let cleanHTML = DOMPurify.sanitize(homeBuilders);
    return cleanHTML.replace(/<\/p>|<p>|<\/p>$/g, "").replace(/^<p>$/g, "");
  };
  return (
    <section className="about-cards">
      <div className="container">
        <h2 className="text-center">{aboutcard.heading}</h2>
        <div className="d-flex animate justify-content-center ">
          <div className="blues"></div>
          <div className="lines"></div>
        </div>
        <p
          className="text-center paragraph"
          dangerouslySetInnerHTML={{
            __html: sanitizeHTML(aboutcard.description),
          }}
        />
      </div>
      <div className="container my-lg-5 hexagon">
        <div className="row my-xl-2">
          <div className="col-lg-3 mt-4 col-sm-6 ">
            <div className="hex" style={{ background: "#07AF7C" }}>
              <div className="hex-background">
                <div className="text-center hex-cards">
                  <img
                    src="{aboutcard.cards.card1img.node.sourceUrl}"
                    className="img2 img-fluid"
                    alt="."
                  />
                  <h5 class="heads">{aboutcard.cards.card1name}</h5>
                  <p className="para">{aboutcard.cards.card1text}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 mt-4 col-sm-6 ">
            <div className="hex" style={{ background: "#FC6401" }}>
              <div className="hex-background">
                <div className="text-center hex-cards">
                  <img
                    src="{aboutcard.cards.card1img.node.sourceUrl}"
                    className="img2 img-fluid"
                  />
                  <h5 className="heads">{aboutcard.cards.card2name}</h5>
                  <p className="para">{aboutcard.cards.card2text}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 mt-4 col-sm-6 ">
            <div className="hex" style={{ background: "#0773AF" }}>
              <div className="hex-background">
                <div className="text-center hex-cards">
                  <img
                    src="../../../images/first.png"
                    className="img2 img-fluid"
                  />
                  <h5 className="heads">{aboutcard.cards.card3name}</h5>
                  <p className="para">{aboutcard.cards.card3text}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3  mt-4 col-sm-6 ">
            <div className="hex" style={{ background: "#9D53FB" }}>
              <div className="hex-background">
                <div className="text-center hex-cards">
                  <img
                    src="../../../images/first.png"
                    className="img2 img-fluid"
                  />
                  <h5
                    className="heads"
                    dangerouslySetInnerHTML={{
                      __html: aboutcard.cards.card4name,
                    }}
                  />
                  <p className="para">{aboutcard.cards.card4text}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Cardsabout;
