import React from "react";
import "./Load.scss";
import { Container, Nav, Navbar } from "react-bootstrap";
function Menus(props) {
  const { handleImageChange, port } = props;

  return (
    <div className="menu my-xl-5 my-lg-4">
      <Navbar expand="lg ">
        <Container  >
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav" >
            <Nav className=" py-3 mx-lg-auto" defaultActiveKey="1" variant="underline ">
             
              <Nav.Link
                className="nav-link me-auto ms-4"
                eventKey="1"
                onClick={() => handleImageChange("All")}
              >
                All
              </Nav.Link>
              <span className="vl" />
              <Nav.Link
                eventKey="2"
                className="nav-link me-auto"
                onClick={() => handleImageChange("Branding")}
              >
                Branding
              </Nav.Link>
              <span className="vl" />

              <Nav.Link
                eventKey="3"
                className=" me-auto"
                onClick={() => handleImageChange("Business Cards")}
              >
                Business Cards
              </Nav.Link>

              <span className="vl" />

              <Nav.Link
                eventKey="4"
                className=" me-auto"
                onClick={() => handleImageChange("Logos")}
              >
                Logos
              </Nav.Link>

              <span className="vl" />

              <Nav.Link
                eventKey="5"
                className="nav-link me-auto"
                onClick={() => handleImageChange("Mobile Apps")}
              >
                Mobile Apps
              </Nav.Link>

              <span className="vl" />

              <Nav.Link
                eventKey="6"
                className="nav-link me-auto"
                onClick={() => handleImageChange("Mockups")}
              >
                Mockups
              </Nav.Link>

              <span className="vl" />

              <Nav.Link
                eventKey="7"
                className="nav-link me-auto"
                onClick={() => handleImageChange("SMO Banners")}
              >
                SMO Banners
              </Nav.Link>

              <span className="vl" />

              <Nav.Link
                eventKey="8"
                className="nav-link me-auto"
                onClick={() => handleImageChange("Web Development")}
              >
                Web Development
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default Menus;
