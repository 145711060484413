import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'react-bootstrap';
import './Load.scss';
import Menu from './Menu';

function LoadMore(props) {
  const [shown, setShown] = useState(6);
  const [loading, setLoading] = useState(false);
  const port = props.homeBuilder;
  const [selectedImages, setSelectedImages] = useState(port.imageicon.filter(image => image.selectTheWorkGallerySection));
  const lastImageRef = useRef(null); // Ref for the last image element

  useEffect(() => {
    // Scroll to the last image when shown is updated
    if (lastImageRef.current) {
      lastImageRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [shown]); // Trigger effect whenever shown changes

  const handleMore = () => {
    if (!loading) {
      const newShown = shown + 3;
      setLoading(true);
      setTimeout(() => {
        setShown(newShown);
        setLoading(false);
      }, 800);
    }
  };

  const handleLess = () => {
    if (!loading) {
      setLoading(true);
      setTimeout(() => {
        setShown(Math.max(3, shown - 3));
        setLoading(false);
      }, 500);
    }
  };

  const handleImageChange = (selectTheWorkGallerySection) => {
    const newImages = port.imageicon.filter(image => image.selectTheWorkGallerySection === selectTheWorkGallerySection);
    setSelectedImages(newImages);
    setShown(6);  
  };

  return (
    <section className='load-more'>
      <div className='container text-center'>
        <h2>{port.heading}</h2>
        <div className="d-flex animate justify-content-center">
          <div className="blue-xs"></div>
          <div className="line-xs"></div>
        </div>
      
        <Menu handleImageChange={handleImageChange} port={port} />
        <div className='row mb-3 mt-3'>
          {selectedImages.slice(0, shown).map((el, index) => (
            <div className='col-lg-4 pt-3' key={index} ref={index === shown - 1 ? lastImageRef : null}>
              <div className="card for bg-muted">
                <img src={`${el.images.node.sourceUrl}`} className="img-fluid img" alt="..." />
                <div className="card-body ref">
                  <h5 className="card-title text-success mt-4">
                    Consumer Insight
                  </h5>
                  <p>Business Improved</p>
                </div>
              </div>
            </div>
          ))}
        </div>
        {loading && (
          <div className="text-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}
        {!loading && (
          <>
            {shown < selectedImages.length && (
              <Button className="my-lg-4 ms-lg-2 more" onClick={handleMore}>Load More</Button>
            )}
            {shown > 6 && (
              <Button className="my-lg-4 ms-lg-2 less" onClick={handleLess}>Load Less</Button>
            )}
          </>
        )}
      </div>
    </section>
  );
}

export default LoadMore;
