import React from "react";
import "./CardTable.scss";
import SanitizeHTML from "../../SanitizeHTML";

const CardTable = (props) => {
  const tablecard = props.homeBuilder;

  return (
    <section className="react-card my-lg-4 my-xl-5">
      <div className="container">
        <div className="row">
          <div className="col-12 mb-lg-3 mb-xl-4">
            <h2 dangerouslySetInnerHTML={{ __html: tablecard.heading }} />
            <div className="d-flex animate justify-content-start">
              <div className="blues"></div>
              <div className="lines"></div>
            </div>
            {tablecard.description && (
              <p
                dangerouslySetInnerHTML={{
                  __html: SanitizeHTML(tablecard.descriptionsifAny),
                }}
                className="paragraph"
              />
            )}
          </div>
          <div className="">
            <div className="d-flex justify-content-between flex-cards ">
              {tablecard.createcards.slice(0, 2).map((card) => (
                <div className="card d-flex" key={card.id}>
                  <div
                    className="vl"
                    style={{ borderLeft: `20px solid ${card.vlColor}` }}
                  />
                  <div className="card-body">
                    <h3>{card.cardshead}</h3>
                    <p
                      className="para"
                      dangerouslySetInnerHTML={{
                        __html: SanitizeHTML(card.cardsdescriptions),
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>

            { tablecard.createcards.length > 2 && (
              <>
                {tablecard.createcards[2] && (
                  <div className="col-12 d-flex justify-content-center">
                    <div className="card d-flex justify-content-center">
                      <div
                        className="vl "
                        style={{
                          borderLeft: `20px solid ${tablecard.createcards[2].vlColor}`,
                        }}
                      />
                      <div className="card-body">
                        <h3>{tablecard.createcards[2].cardshead}</h3>
                        <p
                          className="para"
                          dangerouslySetInnerHTML={{
                            __html: SanitizeHTML(
                              tablecard.createcards[2].cardsdescriptions
                            ),
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div className="d-flex justify-content-between flex-cards">
                  {tablecard.createcards.slice(3).map((card) => (
                    <div className="card d-flex" key={card.id}>
                      <div
                        className="vl"
                        style={{ borderLeft: `20px solid ${card.vlColor}` }}
                      />
                      <div className="card-body">
                        <h3>{card.cardshead}</h3>
                        <p
                          className="para"
                          dangerouslySetInnerHTML={{
                            __html: SanitizeHTML(card.cardsdescriptions),
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default CardTable;
