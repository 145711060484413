import React, { useEffect, useState } from "react";
import "./Card.scss";
import "../Banner/Home.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import DOMPurify from "dompurify";
function CardPage(props) {
  const card = props.homeBuilder;
  const sanitizeHTML = (homeBuilders) => {
    let cleanHTML = DOMPurify.sanitize(homeBuilders);
     return cleanHTML.replace(/<\/p>|<p>|<\/p>$/g, "").replace(/^<p>$/g, "");
  };
  const [smallScreen, setSmallScreen]= useState(window.innerWidth < 1280);
  useEffect (()=>{
    const handleResize = () =>{
      setSmallScreen (window.innerWidth < 1280);
    };
    window.addEventListener("resize", handleResize);
return () => {
  window.removeEventListener("resize", handleResize);
};  
  },[]);
  return (
    <>
      <section className="cards">
        <div className="container text-center">
          <h2 className="text-center">{card.heading}</h2>
          <div className="d-flex animate">
            <div className="blue-sm"></div>
            <div className="line-sm"></div>
          </div>
{
  smallScreen ? (
    <Swiper
    className="mySwiper my-3"
    modules={[Pagination]}
    pagination={{ clickable: true }}
    breakpoints={{
      375: {
        slidesPerView: 1,
        spaceBetween: 30,
      },
      549: {
        slidesPerView: 1.5,
        spaceBetween: 15,
      },
      767: {
        slidesPerView: 2,
        spaceBetween: 10,
      },
      982: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
  
    }}
  >
<SwiperSlide>
<div className="card card1">
                <div className="cards1">
                  <h5 className="card-title  head">
                    {card.groupsofcards.card1head}
                  </h5>
                </div>
                <div className="card-body ">
                  <p
                    className="card-text para"
                    dangerouslySetInnerHTML={{
                      __html: sanitizeHTML(card.groupsofcards.card1description),
                    }}
                  />
                </div>
              </div>
              {/* <div className="row">
                <div className="col-lg-11">
                  <div className="d-flex justify-content-center">
                    <div className="vl-green" />
                  </div>
                  <div className="d-flex justify-content-center">
                    <div className="green">
                      <div></div>
                    </div>
                  </div>
                </div>
              </div> */}
</SwiperSlide>
<SwiperSlide>
<div className="card card2">
                <div className="cards2">
                  <h5 className="card-title head">
                    {card.groupsofcards.card2head}
                  </h5>
                </div>
                <div className="card-body ">
                  <p
                    className="card-text para"
                    dangerouslySetInnerHTML={{
                      __html: sanitizeHTML(card.groupsofcards.card2description),
                    }}
                  />
                </div>
              </div>
              {/* <div className="row">
                <div className="col-lg-11">
                  <div className="d-flex justify-content-center">
                    <div className="vl-blue" />
                  </div>
                  <div className="d-flex justify-content-center">
                    <div className="sky-blue">
                      <div></div>
                    </div>
                  </div>
                </div>
              </div> */}
</SwiperSlide>
<SwiperSlide>
<div className="card card3">
                <div className="cards3">
                  <h5 className="card-title head">
                    {card.groupsofcards.card3head}
                  </h5>
                </div>
                <div className="card-body">
                  <p
                    className="card-text para"
                    dangerouslySetInnerHTML={{
                      __html: sanitizeHTML(card.groupsofcards.card3description),
                    }}
                  />
                </div>
              </div>
              {/* <div className="row">
                <div className="col-lg-11">
                  {" "}
                  <div className="d-flex justify-content-center">
                    <div className="vl-violent" />
                  </div>
                  <div className="d-flex justify-content-center">
                    <div className="violent">
                      <div></div>
                    </div>
                  </div>
                </div>
              </div> */}
</SwiperSlide>
<SwiperSlide>
<div className="card card4">
<div className="cards4">
                  <h5 className="card-title head ">
                    {card.groupsofcards.card4head}
                  </h5>
                </div>
                <div className="card-body">
                  <p
                    className="card-text para"
                    dangerouslySetInnerHTML={{
                      __html: sanitizeHTML(card.groupsofcards.card4description),
                    }}
                  />
                </div> 
              </div>
              {/* <div className="row">
                <div className="col-lg-11">
                  <div className="d-flex justify-content-center">
                    <div className="vl-lgreen" />
                  </div>
                  <div className="d-flex   justify-content-center ">
                    <div className="light-green ">
                      <div></div>
                    </div>
                  </div>
                </div>
              </div> */}
</SwiperSlide>
<SwiperSlide>
<div className="card card5">
<div className="cards5">
                  <h5 className="card-title head">
                    {card.groupsofcards.card5head}
                  </h5>
                </div>
                <div class="card-body ">
                  <p
                    className="card-text para"
                    dangerouslySetInnerHTML={{
                      __html: sanitizeHTML(card.groupsofcards.card5description),
                    }}
                  />
                </div>
                
              </div>
              {/* <div className="row">
                <div className="col-lg-11">
                  <div className="d-flex justify-content-center">
                    <div className="vl-sblue" />
                  </div>
                  <div className="d-flex   justify-content-center ">
                    <div className="blue ">
                      <div></div>
                    </div>
                  </div>
                </div>
              </div> */}
</SwiperSlide>
<hr className="mb-5"/>
    </Swiper>
  )
  :
  (
<>
<div className="d-flex justify-content-between mt-5 row ">
            <div className="col-lg-3 col-md-6">
              <div className="card card1">
                <div className="cards1">
                  <h5 className="card-title  head">
                    {card.groupsofcards.card1head}
                  </h5>
                </div>
                <div className="card-body ">
                  <p
                    className="card-text para"
                    dangerouslySetInnerHTML={{
                      __html: sanitizeHTML(card.groupsofcards.card1description),
                    }}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-lg-11">
                  <div className="d-flex justify-content-center">
                    <div className="vl-green" />
                  </div>
                  <div className="d-flex justify-content-center">
                    <div className="green">
                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 ms-auto">
              <div className="card card2">
                <div className="cards2">
                  <h5 className="card-title head">
                    {card.groupsofcards.card2head}
                  </h5>
                </div>
                <div className="card-body ">
                  <p
                    className="card-text para"
                    dangerouslySetInnerHTML={{
                      __html: sanitizeHTML(card.groupsofcards.card2description),
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-11">
                  <div className="d-flex justify-content-center">
                    <div className="vl-blue" />
                  </div>
                  <div className="d-flex justify-content-center">
                    <div className="sky-blue">
                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3  col-md-6 ms-auto">
              <div className="card card3">
                <div className="cards3">
                  <h5 className="card-title head">
                    {card.groupsofcards.card3head}
                  </h5>
                </div>
                <div className="card-body">
                  <p
                    className="card-text para"
                    dangerouslySetInnerHTML={{
                      __html: sanitizeHTML(card.groupsofcards.card3description),
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-11">
                  {" "}
                  <div className="d-flex justify-content-center">
                    <div className="vl-violent" />
                  </div>
                  <div className="d-flex justify-content-center">
                    <div className="violent">
                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="line my-3" />
          <div className="d-flex justify-content-evenly cards-bottom row pb-lg-3">
            <div className="col-lg-4">
              <div className="row">
                <div className="col-lg-10">
                  <div className="d-flex   justify-content-center ">
                    <div className="light-green ">
                      <div></div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center">
                    <div className="vl-lgreen" />
                  </div>
                </div>
              </div>
              <div className="card card4">
                <div className="card-body">
                  <p
                    className="card-text para"
                    dangerouslySetInnerHTML={{
                      __html: sanitizeHTML(card.groupsofcards.card4description),
                    }}
                  />
                </div>
                <div className="cards4">
                  <h5 className="card-title head ">
                    {card.groupsofcards.card4head}
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-lg-4 ">
              <div className="row">
                <div className="col-lg-10">
                  <div className="d-flex   justify-content-center ">
                    <div className="blue ">
                      <div></div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center">
                    <div className="vl-sblue" />
                  </div>
                </div>
              </div>
              <div className="card card5">
                <div class="card-body ">
                  <p
                    className="card-text para"
                    dangerouslySetInnerHTML={{
                      __html: sanitizeHTML(card.groupsofcards.card5description),
                    }}
                  />
                </div>
                <div className="cards5">
                  <h5 className="card-title head">
                    {card.groupsofcards.card5head}
                  </h5>
                </div>
              </div>
            </div>
          </div></>
  )
}

         
        </div>
      </section>
    </>
  );
}

export default CardPage;