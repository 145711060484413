import React from "react";
import "./RepeatCards.scss";
import img3 from "../../../images/img3.png";
import img4 from "../../../images/img4.png";
import img1 from "../../../images/img1.png";
import img2 from "../../../images/img2.png";
import DOMPurify from "dompurify";

function Repeatcard(props) {
  let cards = props.homeBuilder;
  const sanitizeHTML = (homeBuilder) => {
    let cleanHTML = DOMPurify.sanitize(homeBuilder);
    return cleanHTML.replace(/<\/p>|<p>|<\/p>$/g, "").replace(/^<p>$/g, "");
  };
  return (
    <section className="repeat-cards">
      <div className="container ">
        <div className="d-flex icons justify-content-between ">
          <img src={`${img1}`} className="icon-images me-lg-5" />
          <img src={`${img2}`} className="icon-images ms-lg-4" />
        </div>
        <h2 className="text-center">{cards.heading}</h2>

        <div className="d-flex animate justify-content-center">
          <div className="blue-xs"></div>
          <div className="line-xs"></div>
        </div>
        <div className="d-flex icon justify-content-around">
          <img src={`${img3}`} className="icon-images " />
          <img src={`${img4}`} className="icon-images  " />
        </div>
      </div>
      <div className="container py-5">
        <div className="row ">
          {cards.createcards.map((el, index) => (
            <div className="col-lg-4  mt-4 col-md-6" key={index}>
              <div
                className="card "
                style={{
                  border: `${cards.border}`,
                  borderRadius: `${cards.borderRadius}`,
                }}
              >
                <div className="img">
                  <img src="..." alt="img" className="img-fluid " />
                </div>
                <div className="card-body  text-center">
                  <h3 className="card-title">{el.cardshead}</h3>
                  <p
                    className="card-text para"
                    dangerouslySetInnerHTML={{
                      __html: sanitizeHTML(el.cardsdescriptions),
                    }}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Repeatcard;
