import React from "react";
import "./Cards2.scss";
import SanitizeHTML from "../../SanitizeHTML";
function Angularcard2(props) {
  const cards = props.homeBuilder;
  return (
    <section className="circle-top-cards" style={{backgroundColor:cards.backgroundColor}}>
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <h2>{cards.heading}</h2>
            <div className="d-flex animate justify-content-center ">
              <div className="blues"></div>
              <div className="lines"></div>
            </div>
            <p
              className="paragraph"
              dangerouslySetInnerHTML={{
                __html: SanitizeHTML(cards.descriptionsifAny),
              }}
            />
          </div>
        </div>
        <div className="row mt-5">
          {cards.createcards.slice(0, 6).map((el, index) => (
            <div className="col-sm-6 col-lg-4 " key={index}>
              <div className="card">
                <div
                  className="circleon-top"
                  style={{ background: el.circularColor }}
                />
                <div className="card-body text-center">
                  <h3>{el.cardshead}</h3>
                  <p
                    className="para"
                    dangerouslySetInnerHTML={{
                      __html: SanitizeHTML(el.cardsdescriptions),
                    }}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="row mb-lg-4" style={{ placeContent: "center" }}>
          {cards.createcards.slice(6, 9).map((el, index) => (
            <div className="col-lg-4 col-sm-6 " key={index}>
              <div className="card">
                <div
                  className="circleon-top"
                  style={{ background: el.circularColor }}
                />
                <div className="card-body text-center">
                  <h3>{el.cardshead}</h3>
                  <p
                    className="para"
                    dangerouslySetInnerHTML={{
                      __html: SanitizeHTML(el.cardsdescriptions),
                    }}
                  />
                </div>
              </div>
            </div>
          ))}
          {/* <div className='col-4'>
                <div className='card'>
                    <div className='circleon-top'/>
                    <div className="card-body">
                        <h3>hlo</h3>
                        <p>Lorem, ipsum.</p>
                    </div>
                </div>
            </div>
            <div className='col-4'>
                <div className='card'>
                    <div className='circleon-top'/>
                    <div className="card-body">
                        <h3>hlo</h3>
                        <p>Lorem, ipsum.</p>
                    </div>
                </div>
            </div>
            <div className='col-4'>
                <div className='card'>
                    <div className='circleon-top'/>
                    <div className="card-body">
                        <h3>hlo</h3>
                        <p>Lorem, ipsum.</p>
                    </div>
                </div>
            </div> */}
        </div>
      </div>
    </section>
  );
}

export default Angularcard2;
