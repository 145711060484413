import { gql, useQuery } from "@apollo/client";
import React from "react";
import { useParams } from "react-router-dom";
import "./Layout.scss";
import Cards from "./Home/First/First";
import HomeBanner from "./Home/Banner/Banner";
import Navbars from "../Navbar";
const GET_QUERY = gql`
  query MyQuery2{
    pageBy(uri:"/") {
      homeBuilder {
        page {
          ... on HomeBuilderPageFourCardsLayout {
            heading
            description
            selectLayoutStyle
            stylingCards {
              border2Card
              border3Card
              border4Card
              border
            }
            cards {
              card1name
              card1text
              card2name
              card2text
              card3name
              card3text
              card4name
              card4text
              card1img {
                node {
                  sourceUrl
                }
              }
              card2img {
                node {
                  sourceUrl
                }
              }
              card3img {
                node {
                  sourceUrl
                }
              }
              card4img {
                node {
                  sourceUrl
                }
              }
            }
          }
          ... on HomeBuilderPageBannerLayout {
            selectLayoutsection
            stylingSection {
              backgroundPosition
              backgroundSize
              backgroundImage {
                node {
                  sourceUrl
                }
              }
            }
            group {
              heading
              headerText
              button {
                title
                url
              }

              paragraph
              images {
                node {
                  sourceUrl
                }
              }
            }
          }
        }
      }
    }
  }
`;
function Layout() {
  const { uri } = useParams();
  const uriString = String(uri);
  const { loading, error, data } = useQuery(GET_QUERY, {
    variables: { uri: uriString },
  });
  console.log("uri:", uri);
  if (loading) {
    return (
      <div class="d-flex justify-content-center">
        <strong role="status">Loading...</strong>
        <div class="spinner-border " aria-hidden="true"></div>
      </div>
    );
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  const { pageBy } = data;
  const homeBuilders = pageBy.homeBuilder.page;

  return (
    <div className="banner">
      <Navbars/>
      {homeBuilders.map((homeBuilder, index) => {
        if (homeBuilder.__typename === "HomeBuilderPageBannerLayout") {
          if (homeBuilder.selectLayoutsection === "Home") {
            return (
              <>
                <HomeBanner homeBuilder={homeBuilder} />
              </>
            );
          }
        
        } else if (
          homeBuilder.__typename === "HomeBuilderPageFourCardsLayout"
        ) {
          if (homeBuilder.selectLayoutStyle === "layout1") {
            return (
              <>
                <Cards homeBuilder={homeBuilder} />
              </>
            );
          }
        }
      })}
    </div>
  );
}

export default Layout;
