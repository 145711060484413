import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "./Feedback.scss";
import { Pagination } from "swiper/modules";
import DOMPurify from "dompurify";
function Feedback(props) {
  const feedback = props.homeBuilder;
  const sanitizeHTML = (homeBuilders) => {
    let cleanHTML = DOMPurify.sanitize(homeBuilders);
     return cleanHTML.replace(/<\/p>|<p>|<\/p>$/g, "").replace(/^<p>$/g, "");
  };
  useEffect(() => {
    window.AOS.init();
  }, []);
  return (
    <>
      <section className="review">
        <div className="container">
          <div className="row">
            <h2 className="text-center mt-3">{feedback.heading}</h2>
            <div className="d-flex animate justify-content-center ">
              <div className="blue-sm"></div>
              <div className="line-sm"></div>
            </div>
            <Swiper
              className="mySwiper"
              modules={[Pagination]}
              pagination={{ clickable: true }}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                640: {
                  slidesPerView: 2,
                  spaceBetween: 30,
                },
              992: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                1500: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                1800 :{
                  slidesPerView: 3,
                  spaceBetween :25,
                },
                2500 : {
                  slidesPerView: 3,
                  spaceBetween: 15,
                }
              }}
            >
              {feedback.review.map((el, index) => (
                <SwiperSlide className="pb-3 ms-lg-4" key={index}>
                  <div
                    className="card my-5"
                  >
                    <img
                      src={el.images.node.sourceUrl}
                      className="card-img-top p-3 "
                    />
                    <div className="card-body align-middle">
                      <p className="card-text paragraph" dangerouslySetInnerHTML={{ __html: sanitizeHTML(el.content), }} />
                    </div>
                  </div>
               <div className="connection-container d-flex justify-content-start">
                <div className="image">
                <img src={el.clientimage.node.sourceUrl} className="img-fluid" alt="reviewer-image"/>
                </div>
                <div className="text ms-3">
                  <h3>{el.name}.</h3>
                  <p>{el.posititon}</p>
                </div>
               </div>
               {/* <div className="row">
                <div className="col-lg-3">
                  <img src={el.images.node.sourceUrl} style={{ background:
                          "linear-gradient(304.02deg, #005C8F 6.55%, #0583CA 93.45%)",}}/>
                </div>
                <div className="col-lg-9 align-items-start">
                  <h3>lorem</h3>
                  <p>lorme</p>
                </div>
               </div> */}
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </section>
    </>
  );
}

export default Feedback;
