import React from "react";
import "./First.scss";
import DOMPurify from "dompurify";
function Cards(props) {
  const homeBuilde = props.homeBuilder;
  const sanitizeHTML = (homeBuilders) => {
    let cleanHTML = DOMPurify.sanitize(homeBuilders);
     return cleanHTML.replace(/<\/p>|<p>|<\/p>$/g, "").replace(/^<p>$/g, "");
  };
  return (
    <>
      <section className="square-cards " style={{backgroundColor:homeBuilde.bgcolor}}>
        <div className="container">
          {homeBuilde.heading && homeBuilde.description &&(
        
            <div className=" row head text-center">
              <div className="col-12">
            <h2>{homeBuilde.heading}</h2>
            <div className="d-flex animate justify-content-center">
            <div className="blue-lg"></div>
            <div className="line-lg"></div>
          </div>
          <p className="d-flex justify-content-center center pb-5 paragraph" dangerouslySetInnerHTML={{__html:sanitizeHTML(homeBuilde.description)}}/>
          </div>
          </div>
          )}
          <div className="row ">
            <div className="col-xl-3 col-lg-6 m-l col-md-6 col-sm-6 pt-2 pb-3">
              <div
                className="card "
                style={{ border: homeBuilde.stylingCards.border }}
              >
                <div className="img">
                  <img
                    className="img-fluid img1"
                    style={{ border: homeBuilde.stylingCards.border }}
                    src={homeBuilde.cards.card1img.node.sourceUrl}
                  />
                </div>
                <div class="card-body">
                  <h3 class="card-title">{homeBuilde.cards.card1name}</h3>
                  <p
                    class="card-text paragraph"
                    dangerouslySetInnerHTML={{
                      __html:sanitizeHTML(homeBuilde.cards.card1text)
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-6 m-r col-md-6 col-sm-6 pt-2 pb-3">
              <div
                className="card "
                style={{ border: homeBuilde.stylingCards.border2Card }}
              >
                <div className="img">
                  <img
                    className="img-fluid img2"
                    src={homeBuilde.cards.card2img.node.sourceUrl}
                  />
                </div>
                <div class="card-body">
                  <h3 class="card-title">{homeBuilde.cards.card2name}</h3>
                  <p
                    class="card-text paragraph"
                    dangerouslySetInnerHTML={{
                      __html: sanitizeHTML(homeBuilde.cards.card2text),
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-6 m-l col-md-6 col-sm-6 pt-2 pb-3">
              <div
                className="card "
                style={{ border: homeBuilde.stylingCards.border3Card}}
              >
                <div className="img">
                  <img
                    className="img-fluid img3"
                    src={homeBuilde.cards.card3img.node.sourceUrl}
                  />
                </div>
                <div class="card-body">
                  <h3 class="card-title">{homeBuilde.cards.card3name}</h3>
                  <p
                    class="card-text paragraph"
                    dangerouslySetInnerHTML={{
                      __html: sanitizeHTML(homeBuilde.cards.card3text),
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-6 m-r col-md-6 col-sm-6 pt-2 pb-3">
              <div
                className="card"
                style={{ border: homeBuilde.stylingCards.border4Card }}
              >
                <div className="img">
                  <img
                    className="img-fluid img4"
                    src={homeBuilde.cards.card4img.node.sourceUrl}
                  />
                </div>
                <div class="card-body">
                  <h3 class="card-title">{homeBuilde.cards.card4name}</h3>
                  <p
                    class="card-text paragraph"
                    dangerouslySetInnerHTML={{
                      __html: sanitizeHTML(homeBuilde.cards.card4text),
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Cards;
