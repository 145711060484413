import { gql, useQuery } from "@apollo/client";
import React, { useEffect } from "react";
import "../App.scss";
import Intro from "./Home/Intro/Intro";
import HomeService from "./Home/Service/HomeService";
import Perk from "./Home/Perk/Perk";
import CardPage from "./Home/Cards/Cards";
import Client from "./Home/Clients/Client";
import Tech from "./Home/Tech/Tech";
import Portfolio from "./Home/Portfolio/Portfolio";
import Feedback from "./Home/Feedback/Feedback";
import Work from "./Home/Work/Work";
import Layout from "./Layout";
import Navbars from "../Navbar";
import Foot from "./Footer/Foot";
import Seo from "../Seo";
import NotFound from "../NotFound";

const GET_ABOUT = gql`
  query MyQuery2{
    pageBy(uri:"/") {
      homeBuilder {
        page {
          ... on HomeBuilderPageBannerLayout {
            selectLayoutsection
            stylingSection {
              backgroundPosition
              backgroundSize
              backgroundImage {
                node {
                  sourceUrl
                }
              }
            }
            group {
              heading
              headerText
              button {
                title
                url
              }

              paragraph
              images {
                node {
                  sourceUrl
                }
              }
            }
          }
          ... on HomeBuilderPagePerksSectionLayout {
            fieldGroupName
            buttonNameifAny {
              title
              url
            }
            layoutOfContainer
            column1 {
              image {
                node {
                  sourceUrl
                }
              }
            }
            column2 {
              head
              descriptions
              anothercardsif {
                description
                head
              }
              columncards {
                cardshead
                cardsdescription
              }
            }
          }
          ... on HomeBuilderPageReviewcarouselLayout {
            heading
            review {
              content
              name
              posititon
              images {
                node {
                  sourceUrl
                }
              }
              clientimage {
                node {
                  sourceUrl
                }
              }
            }
          }
          ... on HomeBuilderPageTablecardLayout {
            fieldGroupName
            heading

            groupsofcards {
              card5head
              card3description
              card5description
              card4head
              card4description
              card3head
              card2description
              card1head
              card1description
              card2head
            }
          }
          ... on HomeBuilderPageContactpageLayout {
            embededgooglemapcode
            description
            heading
            form {
              anyheading
              buttonname
              contactformlabels {
                firstinput
                fiveinput
                fourthInput
                secondinput
                thirdinput
              }
            }
            contactinfo {
              address
              addresscountry
              adress2
              adress2country
              phonenumber
              gmail
              adress2phonenumber
              adress2gmail
            }
          }

          ... on HomeBuilderPageContainerLayout {
            bgColor
            imageposition
            row {
              col1
              col1paragraph
              col2image {
                node {
                  sourceUrl
                }
              }
            }
          }
          ... on HomeBuilderPageCardscolimageLayout {
            rowcontainer {
              colimage {
                node {
                  sourceUrl
                }
              }

              col2 {
                col2head
                coltext
                col2cards {
                  cardstext
                  cardsicon {
                    node {
                      sourceUrl
                    }
                  }
                }
                button {
                  title
                  url
                }
              }
            }
          }
          ... on HomeBuilderPageRotatecontainerLayout {
            selectLayout
            buttontext {
              text
              buttonname {
                title
                url
              }
              descriptions
            }
            headingandtext {
              heading
              descriptions
              listitem {
                name
              }
            }
            cardsrotates {
              cardhead
              cards2description
              cards2head
              cards3description
              cards3head
              cards4description
              cardsdescription
              cards4head
              cardsimage {
                node {
                  sourceUrl
                }
              }
              cards2image {
                node {
                  sourceUrl
                }
              }
              cards3image {
                node {
                  sourceUrl
                }
              }
              cards4image {
                node {
                  sourceUrl
                }
              }
            }
          }
          ... on HomeBuilderPageImagesheadingLayout {
            heading
            select
            backgroundColor
            imageicon {
              height
              widthinpx
              images {
                node {
                  sourceUrl
                }
              }
            }
          }
          ... on HomeBuilderPageCircleimagetextLayout {
            heading
            firstfield {
              name
              iconpic {
                node {
                  sourceUrl
                }
              }
            }
            secondfield {
              iconname
              iconimage {
                node {
                  sourceUrl
                }
              }
            }
          }

          ... on HomeBuilderPageSimplecardsLayout {
            descriptionsifAny
            heading
            border
            select
            borderRadius
            marginTopinPx
            paddinginPx
            createcards {
              cardshead
              cardsdescriptions
              cardsimage {
                node {
                  sourceUrl
                }
              }
            }
          }
            ... on HomeBuilderPageSeoLayout {
          titleOfPage
          metaTitlesAndDescriptions {
            metaDescriptions
            metaTitles
          }
        }
          ... on HomeBuilderPageFourCardsLayout {
            heading
            description
            selectLayoutStyle
            stylingCards {
              border2Card
              border3Card
              border4Card
              border
            }
            cards {
              card1name
              card1text
              card2name
              card2text
              card3name
              card3text
              card4name
              card4text
              card1img {
                node {
                  sourceUrl
                }
              }
              card2img {
                node {
                  sourceUrl
                }
              }
              card3img {
                node {
                  sourceUrl
                }
              }
              card4img {
                node {
                  sourceUrl
                }
              }
            }
          }
        }
      }
    }
  }
`;
const Home=()=> {
  useEffect(() => {
    const handleMouseMove = (e) => {
      const cursor = document.getElementById("cursor");
      const dot = document.getElementById("dot");
      if (cursor) {
        cursor.style.left = e.clientX + "px";
        cursor.style.top = e.clientY + "px";
      }
      if (dot) {
        dot.style.left = e.clientX- dot.offsetWidth / 2 + "px";
        dot.style.top = e.clientY - dot.offsetHeight / 2 + "px";
      }
    }; 

    document.body.addEventListener("mousemove", handleMouseMove);

    return () => {
      document.body.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  const {loading, error, data } = useQuery (GET_ABOUT);


   if (loading) {
    return (
      <div className=" spinner-container ">
      <div className="spinner-border" role="status">
  <span className="visually-hidden">Loading...</span>
</div>
</div>
    );
  }

  if (error || !data?.pageBy) {
    return <NotFound />;
  }

  const { pageBy } = data;
  const homeBuilders = pageBy.homeBuilder.page;

  return (
    <>
   
      <div className="app" id="cursor"></div>
      <div id="dot"></div> 
      {homeBuilders.map((homeBuilder, index) => {
if (homeBuilder.__typename=== "HomeBuilderPageSeoLayout") {
  return (
    <>
    <Seo homeBuilder={homeBuilder}/>
    </>
  )
}
      if (homeBuilder.__typename === "HomeBuilderPageBannerLayout" || 
      homeBuilder.__typename==="HomeBuilderPageFourCardsLayout") {
            if(homeBuilder.selectLayoutsection==="Home"){
          return (
            <>
              <Layout />
            </>
          )}
        
      else return null;
      }
       else if (
          homeBuilder.__typename === "HomeBuilderPageContainerLayout"
        ) {
          return (
            <>
              <Intro homeBuilder={homeBuilder} />
            </>
          );
        }
         else if (
          homeBuilder.__typename === "HomeBuilderPageCardscolimageLayout"
        ) {
          return (
            <>
              <HomeService homeBuilder={homeBuilder} />
            </>
          );
        }
         else if (
          homeBuilder.__typename === "HomeBuilderPagePerksSectionLayout"
        ) {
          if (homeBuilder.layoutOfContainer === "Home List") {
            return (
              <>
                <Perk homeBuilder={homeBuilder} />
              </>
            );
          } else return null;
        }
         else if (
          homeBuilder.__typename === "HomeBuilderPageTablecardLayout"
        ) {
          return (
            <>
              <CardPage homeBuilder={homeBuilder} />
            </>
          );
        } 
        else if (
          homeBuilder.__typename === "HomeBuilderPageRotatecontainerLayout"
        ) {
          if (homeBuilder.selectLayout === "Clients") {
            return (
              <>
                <Client homeBuilder={homeBuilder} />
              </>
            );
          } else return null;
        }
         else if (
          homeBuilder.__typename === "HomeBuilderPageCircleimagetextLayout"
        ) {
          return (
            <>
              <Tech homeBuilder={homeBuilder} />
            </>
          );
        } 
        else if (
          homeBuilder.__typename === "HomeBuilderPageImagesheadingLayout"
        ) {
          if (homeBuilder.select === "Images In Box")
            return (
              <>
                <Work homeBuilder={homeBuilder} />
              </>
            );
          else if (homeBuilder.select === "Carousel Images") {
            return <Portfolio homeBuilder={homeBuilder} />;
          }
        }
        else if (
          homeBuilder.__typename === "HomeBuilderPageReviewcarouselLayout"
        ) {
          return (
            <>
              <Feedback homeBuilder={homeBuilder} />
            </>
          );
        }
        else {
          return null;
        }
      })}
      <Foot/>
    </>
  );
}

export default Home;
