import React, { useEffect } from "react";
import "./Client.scss";
import { Card } from "react-bootstrap";
import DOMPurify from "dompurify";
import { NavLink } from "react-router-dom";
function Client(props) {
  useEffect(() => {
    window.AOS.init({
      once:true,});
  }, []);
  const client = props.homeBuilder;
  const sanitizeHTML = (homeBuilders) => {
    let cleanHTML = DOMPurify.sanitize(homeBuilders);
     return cleanHTML.replace(/<\/p>|<p>|<\/p>$/g, "").replace(/^<p>$/g, "");
  };
  return (
    <>
      <section
        className="clients"
        style={{ backgroundColor: "#F7FAFD" }}
      >
        <div className="container">
          <div className="row  ">
            <div className="col-lg-6 col" data-aos="fade-up-right" data-aos-duration="850">
              {/* <div className="container">
                <div className="row  "> */}
                  <div className="rotate ">
                    <div className="d-flex justify-content-lg-center  ">
                      <Card
                        className="blues mx-2 text-center"
                      >
                        <Card.Body className="cardtitle">
                          <div className="flip">
                            <Card.Img
                              variant="top"
                              src={
                                client.cardsrotates.cardsimage.node.sourceUrl
                              }
                            />
                          </div>
                          <Card.Title>
                            <h5>
                            {client.cardsrotates.cardhead}
                            </h5>
                          </Card.Title>
                          <Card.Text
                          className="para"
                            dangerouslySetInnerHTML={{
                              __html: sanitizeHTML(client.cardsrotates.cardsdescription),
                            }}
                          />
                        </Card.Body>
                      </Card>
                      <Card
                        className="red ms-2 text-center"
                      >
                        <Card.Body className="cardtitle">
                          <div className="flip">
                            <Card.Img
                              variant="top"
                              src={
                                client.cardsrotates.cards2image.node.sourceUrl
                              }
                            />
                          </div>
                          <Card.Title>
                            <h5>
                            {client.cardsrotates.cards2head}
                            </h5>
                          </Card.Title>
                          <Card.Text
                          className="para"
                            dangerouslySetInnerHTML={{
                              __html: sanitizeHTML(client.cardsrotates.cards2description),
                            }}
                          />
                        </Card.Body>
                      </Card>
                    </div>

                    <div className="d-flex justify-content-lg-center ">
                      <Card
                        className="pink mt-3  mx-2 text-center"
                      >
                        <Card.Body className="cardtitle">
                          <div className="flip">
                            <Card.Img
                              variant="top"
                              src={
                                client.cardsrotates.cards3image.node.sourceUrl
                              }
                            />
                          </div>
                          <Card.Title>
                            <h5>
                            {client.cardsrotates.cards3head}
                            </h5>
                          </Card.Title>
                          <Card.Text
                          className="para"
                            dangerouslySetInnerHTML={{
                              __html: sanitizeHTML(client.cardsrotates.cards3description),
                            }}
                          />
                        </Card.Body>
                      </Card>
                      <Card
                        className="green mt-3  ms-2 text-center"
                      >
                        <Card.Body className="cardtitle">
                          <div className="flip">
                            <Card.Img
                              variant="top"
                              src={
                                client.cardsrotates.cards4image.node.sourceUrl
                              }
                            />
                          </div>
                          <Card.Title>
                            <h5>
                            {client.cardsrotates.cards4head}
                            </h5>
                          </Card.Title>
                          <Card.Text
                          className="para"
                            dangerouslySetInnerHTML={{
                              __html: sanitizeHTML(client.cardsrotates.cards4description),
                            }}
                          />
                        </Card.Body>
                      </Card>
                    </div>
                  </div>
                </div>
              {/* </div>
            </div> */}
            <div className="col-lg-6 col2" > 
              <div data-aos="fade-up-left" data-aos-duration="850">
              <h2>{client.headingandtext.heading}</h2>
              <div className="d-flex animate justify-content-start">
                <div className="blues"></div>
                <div className="lines"></div>
              </div>
              <p
                className="client-text paragraph"
                dangerouslySetInnerHTML={{
                  __html: sanitizeHTML(client.headingandtext.descriptions),
                }}
              />
              </div>
              <div className="container-fluid " data-aos="zoom-in-left" data-aos-delay="200" data-aos-duration="1000">
                <div className="row relate">
                  {client.headingandtext.listitem.map((el) => (
                    <div className="d-flex cole pt-2" key={el.id}>
                      <div className="blue-circle">
                        <div className="div"></div>
                      </div>
                      <p className="ms-3 ms-sm-2 mb-lg-3 mb-2 mt-sm-2 para "> {el.name}</p>
                    </div>
                  ))}
                </div> 
              </div>{" "}
            </div>
          </div>
          <hr className="hr" />
          <div
            className="container"
            data-aos="fade-up"
            data-aos-anchor-placement="bottom-bottom"
          >
            <div className="row bottom ">
              <div className="col-lg-6 col-md-6 col-sm-12">
                <h3 className="text-start">{client.buttontext.text}</h3>
                <p
                className="paragraph"
                  dangerouslySetInnerHTML={{
                    __html:sanitizeHTML(client.buttontext.descriptions),
                  }}
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 text-lg-end text-sm-center">
                <button type="button" className="btn btn-primary">
                  <NavLink
                    className="navlink"
                    to={`${client.buttontext.buttonname.url}`}
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    {client.buttontext.buttonname.title}
                  </NavLink>
                </button> 
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Client;
