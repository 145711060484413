import React from "react";
import "./Mission.scss";
import DOMPurify from "dompurify";
function AboutMission(props) {
  let mission = props.homeBuilder;
  const sanitizeHTML = (homeBuilders) => {
    let cleanHTML = DOMPurify.sanitize(homeBuilders);
    return cleanHTML.replace(/<\/p>|<p>|<\/p>$/g, "").replace(/^<p>$/g, "");
  };
  return (
    <section className="missions my-3">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-sm-12 text-container">
            <h2>{mission.headingandtext.heading}</h2>
            <div className="d-flex animate justify-content-start ">
              <div className="blue-lg"></div>
              <div className="line-lg"></div>
            </div>
            <p
              className="paragraph"
              dangerouslySetInnerHTML={{
                __html: sanitizeHTML(mission.headingandtext.descriptions),
              }}
            />
          </div>
          <div className="col-lg-6 col-sm-12 cards-float">
            <div className="row">
              <div className="col-lg-6  col-sm-6">
                <div className="card mb-3 card1">
                  <div className="card-body">
                  {
                    mission.cardsrotates.cardhead && 
                    mission.cardsrotates.cardhead.length < 10  ? (
                      <div className="text-center mt-4">
                    <h2 className="card-title ">{mission.cardsrotates.cardhead}</h2>
                      <p className="card-text paragraph p-0"  dangerouslySetInnerHTML={{
                        __html: sanitizeHTML(
                          mission.cardsrotates.cardsdescription
                        ),
                      }}/>
                      </div>
                    ):(
                      <>  
                    <h3 className="card-title">
                      {mission.cardsrotates.cardhead}
                    </h3>
                    <p
                      className="card-text para "
                      dangerouslySetInnerHTML={{
                        __html: sanitizeHTML(
                          mission.cardsrotates.cardsdescription
                        ),
                      }}
                    />
                    </>) }
                   
                  </div>
                </div>

                <div className="card card2 mt-lg-4">
                  <div className="card-body">
                  {
                    mission.cardsrotates.cards3head && 
                    mission.cardsrotates.cards3head.length < 10  ? (
                      <div className="text-center mt-4">
                    <h2 className="card-title ">{mission.cardsrotates.cards3head}</h2>
                      <p className="card-text paragraph p-0"  dangerouslySetInnerHTML={{
                        __html: sanitizeHTML(
                          mission.cardsrotates.cards3description
                        ),
                      }}/>
                      </div>
                    ):(
                      <>  
                    <h3 className="card-title">
                      {mission.cardsrotates.cards3head}
                    </h3>
                    <p
                      className="card-text para "
                      dangerouslySetInnerHTML={{
                        __html: sanitizeHTML(
                          mission.cardsrotates.cards3description
                        ),
                      }}
                    />
                    </>) }
                    
                  </div>
                </div>
              </div>
              <div className="col-lg-6  pt-4  col-sm-6">
                <div className="card mb-3  card3">
                  <div className="card-body">
                    {
                    mission.cardsrotates.cards2head && 
                    mission.cardsrotates.cards2head.length < 10  ? (
                      <div className="text-center mt-4">
                    <h2 className="card-title ">{mission.cardsrotates.cards2head}</h2>
                      <p className="card-text paragraph p-0"  dangerouslySetInnerHTML={{
                        __html: sanitizeHTML(
                          mission.cardsrotates.cards2description
                        ),
                      }}/>
                      </div>
                    ):(
                      <>  
                    <h3 className="card-title">
                      {mission.cardsrotates.cards2head}
                    </h3>
                    <p
                      className="card-text para "
                      dangerouslySetInnerHTML={{
                        __html: sanitizeHTML(
                          mission.cardsrotates.cards2description
                        ),
                      }}
                    />
                    </>) }
                    
                  </div>
                </div>

                <div className="card card4 mt-g-4">
                  <div className="card-body">
                  {
                    mission.cardsrotates.cards4head && 
                    mission.cardsrotates.cards4head.length < 10  ? (
                      <div className="text-center mt-4">
                    <h2 className="card-title ">{mission.cardsrotates.cards4head}</h2>
                      <p className="card-text paragraph p-0"  dangerouslySetInnerHTML={{
                        __html: sanitizeHTML(
                          mission.cardsrotates.cards4description
                        ),
                      }}/>
                      </div>
                    ):(
                      <>  
                    <h3 className="card-title">
                      {mission.cardsrotates.cards4head}
                    </h3>
                    <p
                      className="card-text para "
                      dangerouslySetInnerHTML={{
                        __html: sanitizeHTML(
                          mission.cardsrotates.cards4description
                        ),
                      }}
                    />
                    </>) }
                  
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutMission;
