import React from "react";
import "./Carousel.scss";
import Zoom from 'react-medium-image-zoom'
import "swiper/css";
import { Pagination } from "swiper/modules";
import { SwiperSlide, Swiper } from "swiper/react";
function Carousel(props) {
  const gallery = props.homeBuilder;
  return (
    <section className="carousel-gallery">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center mt-5">
            <h2>{gallery.heading}</h2>
            <div className="d-flex animate justify-content-center">
                <div className="blue" ></div>
                <div className="line" ></div>
              </div>
          </div>
        </div>
        <div className="row py-5">
          <Swiper
            className="mySwiper mb-5"
            modules={[Pagination]}
            spaceBetween={38}
            slidesPerView={1}
            pagination={{ clickable: true }}
          >
            {gallery.galleryImages.map((el, index) => (
              <SwiperSlide key={index}>
                <div className="row">
                  <div className="col-6">
                    <div className="row">
                      <div className="col-6">
                        <Zoom>
                        <img
                          loading="lazy"
                          src={`${el.imageOne.node.sourceUrl}`}
                          className="img-fluid img-1"
                        />
                        </Zoom>
                      </div>
                      <div className="col-6">
                        <Zoom>
                    
                        <img
                          loading="lazy"
                          src={`${el.imageTwo.node.sourceUrl}`}
                          className="img-fluid img-2"
                        />
                        </Zoom>
                      </div>
                    </div>
                    <div className="col-12">
                        <Zoom>
                      <img
                        loading="lazy"
                        src={`${el.imageFive.node.sourceUrl}`}
                        className="img-fluid img-3"
                      />
                      </Zoom>
                    </div>
                    <div className="col-12">
                      <Zoom>
                      <img
                        loading="lazy"
                        src={`${el.imageSix.node.sourceUrl}`}
                        className="img-fluid img-4"
                      />
                      </Zoom>

                    </div>
                  </div>
                  <div className="col-6">
                    <div className="row">
                      <div className="col-6">
                      <Zoom>

                        <img
                          loading="lazy"
                          src={`${el.imageThree.node.sourceUrl}`}
                          className="img-fluid img-5"
                        />
                      </Zoom>

                      </div>
                      <div className="col-6">
                      <Zoom>

                        <img
                          loading="lazy"
                          src={`${el.imageFour.node.sourceUrl}`}
                          className="img-fluid img-6"
                        />
                      </Zoom>

                      </div>
                    </div>
                    <div className="col-12">
                      <Zoom>
                      <img
                        loading="lazy"
                        src={`${el.imageSeven.node.sourceUrl}`}
                        className="img-fluid img-7"
                      />
                      </Zoom>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
}
export default Carousel;
