import React from "react";
import "./Service.scss";
import { NavLink } from "react-router-dom";
import SanitizeHTML from "../../SanitizeHTML";
function ServiceCard(props) {
  const service = props.homeBuilder;  
 
  return (
    <section
      className="section-card"
      style={{ backgroundColor: service.bgcolor }}
    >
      <div className="container">
        <div className="row text-center">
          <div className="col-12">
          <h2 >{service.heading}</h2>
          <div className="d-flex animate justify-content-center">
            <div className="blue-sm"></div>
            <div className="line-sm"></div>
          </div>
          <p
            dangerouslySetInnerHTML={{ __html: SanitizeHTML(service.description) }}
            className="paragraph"
          />
        </div>
        </div>
        <div className="row text-center mb-5">
          <div className="col-sm-6 col-xl-3 ">
            <div
              className="card"
              style={{ border: service.stylingCards.border }}
            >
              <img
                className="img-fluid style"
                src={service.cards.card1img.node.sourceUrl}
                alt="img"
              />
              <div className="card-body one">
                <h3 className="card-title">{service.cards.card1name}</h3>
                <p
                  className="card-text para mx-lg-3 mx-xl-0"
                  dangerouslySetInnerHTML={{ __html: SanitizeHTML(service.cards.card1text) }}
                />
                 <NavLink  className="navs" to="/web-development">
                <img src={service.ifSelect.button1.node.sourceUrl} />
              </NavLink>
              </div>
             
            </div>
          </div>
          <div className="col-xl-3 col-sm-6">
            <div
              className="card"
              style={{ border: service.stylingCards.border2Card }}
            >
              <img
                className="img-fluid style"
                src={service.cards.card2img.node.sourceUrl}
                alt="img"
              />
              <div className="card-body two">
                <h3 className="card-title">{service.cards.card2name}</h3>
                <p
                  className="card-text para mx-lg-3 mx-xl-0 "
                  dangerouslySetInnerHTML={{ __html: SanitizeHTML(service.cards.card2text) }}
                />
                <NavLink className="navs" to="/">
                <img src={service.ifSelect.buttonImg2.node.sourceUrl} />
              </NavLink>
              </div>
             
            </div>
          </div>
          <div className="col-xl-3 col-sm-6">
            <div
              className="card"
              style={{ border: service.stylingCards.border3Card }}
            >
              <img
                className="img-fluid style"
                src={service.cards.card3img.node.sourceUrl}
                alt="img"
              />
              <div className="card-body three">
                <h3 className="card-title">{service.cards.card3name}</h3>
                <p
                  className="card-text para mx-lg-3 mx-xl-0 "
                  dangerouslySetInnerHTML={{ __html: SanitizeHTML(service.cards.card3text) }}
                />
                <NavLink  className="navs" to="/">
                <img src={service.ifSelect.buttonimg3.node.sourceUrl} />
              </NavLink>{" "}
              </div>
             
            </div>
          </div>
          <div className="col-xl-3 col-sm-6">
            <div
              className="card"
              style={{ border: service.stylingCards.border4Card }}
            >
              <img
                src={service.cards.card4img.node.sourceUrl}
                className="img-fluid style"
                alt="img"
              />
              <div className="card-body four">
                <h3 className="card-title">{service.cards.card4name}</h3>
                <p
                  className="card-text para mx-lg-3 mx-xl-0 "
                  dangerouslySetInnerHTML={{ __html: SanitizeHTML(service.cards.card4text) }}
                />
                <NavLink  className="navs" to="/">
                <img src={service.ifSelect.button4.node.sourceUrl} />
              </NavLink>{" "}
              </div>
             
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ServiceCard;
