import React from "react";
import { Container, Nav } from "react-bootstrap";
import "./Menus.scss";
function Menu(props) {
  const menu = props.homeBuilder;
  return (
    <section className="menu-list">
      <Container>
        <Nav className=" py-3 mb-2 py-xxl-4 justify-content-center">
          {menu.listItem.map((el, index) => (
            <>
            <Nav.Item
              className=" d-flex justify-content-center mt-2 me-auto ms-4"
              eventKey="1"
              key={index}
            >
              <img src={`${el.icon.node.sourceUrl}`} className="img-fluid " />
             <p className="paragraph pt-1"> {el.listItem}</p>
        
            </Nav.Item>
                  {index !== menu.listItem.length - 1 && (
                    <span className={`vl ${index === 1 ? 'hr' : ''}`}/>
                  )}
                  </>
          ))}

         
        </Nav>
      </Container>
    </section>
  );
}

export default Menu;
