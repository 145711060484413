import React from 'react';

const Admin = () => {
  const wordpressAdminUrl = 'https://staging.avaptech.in/wp-admin';
  
  
    window.location.href = wordpressAdminUrl;
    return null;
 
  
};

export default Admin;