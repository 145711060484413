import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "./Navbar.scss";
import { gql, useQuery } from "@apollo/client";
import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";

const GET_QUERY = gql`
  query MyQuery2 {
    menus {
      edges {
        node {
          name
          navbar {
            button {
              title
              url
            }
            color
            logo {
              node {
                sourceUrl
              }
            }
          }
          menuItems {
            edges {
              node {
                uri
                label
                dropdowns {
                  menuDropdowns {
                    addSubmenu {
                      title
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

function Navbars() {
  const [prevScrollpos, setPrevScrollpos] = useState(window.scrollY);
  const [visible, setVisible] = useState(true);
  const [navbarColor, setNavbarColor] = useState("transparent");
  const [showDropdown, setShowDropdown] = useState({});

  const handleMouseEnter = (index) => {
    setShowDropdown((prevState) => ({
      ...prevState,
      [index]: true,
    }));
  };

  const handleMouseLeave = (index) => {
    setShowDropdown((prevState) => ({
      ...prevState,
      [index]: false,
    }));
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      const isScrolledUp = prevScrollpos > currentScrollPos;

      setPrevScrollpos(currentScrollPos);
      setVisible(isScrolledUp || currentScrollPos === 0);

      if (currentScrollPos > 70) {
        setNavbarColor("white");
      } else {
        setNavbarColor("transparent");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollpos]);

  const { loading, error, data } = useQuery(GET_QUERY);

  if (loading) {
    return <p></p>;
  }
  if (error) {
    return <p>error :{error.message}</p>;
  }

  if (data) {
    const { node } = data.menus.edges[0];
    const { navbar } = node;
    const { menuItems } = node;
    return (
      <>
        <Navbar
          expand="lg"
          className="navbar"
          id="navbar"
          style={{
            fontSize: "16px",
            top: visible ? "0" : "-70px",
            transition: "top 0.3s",
            position: "fixed",
            width: "100%",
            background: navbarColor,
            zIndex: "10000",
          }}
        >
          <Container>
            <Navbar.Brand className="nav me-5">
              <NavLink to="/">
                <img
                  alt="logo"
                  src={navbar.logo.node.sourceUrl}
                  width="170px"
                  height="47px"
                  className="logo"
                />
              </NavLink>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="offcanvasNavbar" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ms-auto">
                {menuItems.edges.map((el, index) =>
                  el.node.dropdowns.menuDropdowns &&
                  el.node.dropdowns.menuDropdowns.length > 0 ? (
                    <Nav.Item
                      className="navbar-links mx-3"
                      key={index}
                      onMouseEnter={() => handleMouseEnter(index)}
                      onMouseLeave={() => handleMouseLeave(index)}
                    >
                      <li className="navbar-dropdown navs" id={`dropdown-${index}`}>
                        <NavLink to={`${el.node.uri}`}>{el.node.label}</NavLink>
                        {showDropdown[index] && (
                          <ul className="dropdown list-unstyled">
                            {el.node.dropdowns.menuDropdowns.map((submenu, subindex) => (
                              <li className="mt-4" key={subindex}>
                                <NavLink className="navs" to={`${submenu.addSubmenu.url}`}>
                                  {submenu.addSubmenu.title}
                                </NavLink>
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>
                    </Nav.Item>
                  ) : (
                    <Nav.Item key={index}>
                      <NavLink className="navs mx-3" to={`${el.node.uri}`}>
                        {el.node.label}
                      </NavLink>
                    </Nav.Item>
                  )
                )}
                <Nav.Item>
                  <Button className="btne btn-primary">
                    <NavLink className="navlink" to={`${navbar.button.url}`}>
                      {navbar.button.title}
                    </NavLink>
                  </Button>
                </Nav.Item>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </>
    );
  }
}

export default Navbars;
