import React from "react";
import "./Pic.scss";
import DOMPurify from "dompurify";
function PicInPic(props) {
  const Pic = props.homeBuilder;
  const sanitizeHTML = (homeBuilders) => {
    const cleanHTML = DOMPurify.sanitize(homeBuilders);
    return cleanHTML.replace(/<\/p>|<p>|<\/p>$/g, "").replace(/^<p>$/g, "");
  };
  return (
    <section className="gallery-imgs">
      <div className="container my-4">
        <div className="row">
          <div className="col-lg-6">
            <div className="d-flex d-one">
              <img
                src={`${Pic.imageOne.node.sourceUrl}`}
                className="img-fluid img-one"
              
              />
              <img
                src={`${Pic.imageTwo.node.sourceUrl}`}
                className="img-fluid img-two"
                style={{
            
                }}
              />
            </div>
          </div>
         <div className="col-lg-6 d-two" style={{marginTop:'68px'}}>
     <h2 dangerouslySetInnerHTML={{__html:Pic.heading}}/>
            <div className="d-flex animate justify-content-start ">
              <div className="blue-xl"></div>
              <div className="line-xl"></div>
            </div>
            <p className="paragraph" dangerouslySetInnerHTML={{__html:sanitizeHTML(Pic.descriptions)}}/>
          </div>
         </div>
        </div>
    </section>
  );
}

export default PicInPic;
