import React from 'react'
import SanitizeHTML from '../../SanitizeHTML';
import { Swiper, SwiperSlide } from "swiper/react";
import './Carousel.scss';
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Pagination } from "swiper/modules";
function Carouselcard(props) {
    const cards= props.homeBuilder;
  return (
    <section className='carousel-card '>
      <div className="container">
        <div className="row">
            <div className="col-12 text-center">
            <h2>{cards.heading}</h2>
            <div className="d-flex animate justify-content-center ">
            <div className="blue-sm"></div>
            <div className="line-sm"></div>
            </div>
         <p className='paragraph' dangerouslySetInnerHTML={{__html:SanitizeHTML(cards.description)}}/>
            </div>
        </div>
        <Swiper
              className="mySwiper"
              modules={[Pagination]}
              loop={true}
              pagination={{ clickable: true, dynamicBullets: true, }}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                  spaceBetween: 5,
                },
                666 : {
                  slidesPerView: 1.5,
                  spaceBetween: 20,
                },
                1023: {
                  slidesPerView:2,
                  spaceBetween: 10,
                },
                1270 : {
                  slidesPerView:2.5,
                  spaceBetween: 10,
                },
                1400 :{
                  slidesPerView: 2.5,
                  spaceBetween :65,
                },
                1600 :{
                  slidesPerView: 2.5,
                  spaceBetween :25,
                },
                2500 : {
                  slidesPerView: 3,
                  spaceBetween: 40,
                }
              }}
            >
              {cards.review.map((el, index) => (
                <SwiperSlide className="pb-3 ms-lg-4" key={index}>
                  <div
                    className="card my-5" >
                         <div
                    className="vl"
                    style={{ borderLeft: `25px solid ${el.border}` }}
                  />
                    <div className="card-body align-middle">
                    <h3>{el.name}</h3>
                      <p className="card-text paragraph" dangerouslySetInnerHTML={{ __html: SanitizeHTML(el.content), }} />
                    </div>
                  </div>
              
                </SwiperSlide>
              ))}
            </Swiper>
      </div>
    </section>
  )
}

export default Carouselcard
