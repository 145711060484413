import React from "react";
import "./Result.scss";
import DOMPurify from "dompurify";
function Result(props) {
  const row = props.homeBuilder;
  const sanitizeHTML = (homeBuilders) => {
    const cleanHTML = DOMPurify.sanitize(homeBuilders);
    return cleanHTML.replace(/<\/p>|<p>|<\/p>$/g, "").replace(/^<p>$/g, "");

  };
  return (
    <section className="line-card">
      <div className="container">
        <div className="row text-center">
          <h2>{row.heading}</h2>
          <div className="d-flex animate justify-content-center">
            <div className="blues"></div>
            <div className="lines"></div>
          </div>
        </div>
        <div className="row">
          {row.createcards.map((el, index) => (
            <div className="col-lg-4 col-sm-4" key={index}>
              <div className="d-flex">
                <div className="card" style={{backgroundImage:`url(${el.cardsimage.node.sourceUrl})`}}>
                  <div className="card-body">
                    <h3 className="card-title">{el.cardshead}</h3>
                    <p
                      className="card-text para"
                      dangerouslySetInnerHTML={{ __html: sanitizeHTML(el.cardsdescriptions) }}
                    />
                  </div>
                </div>
            
                {index !== row.createcards.length - 1 && (
      <div className="vertical-line"></div>
    )}
              </div>
            </div>
          ))}
          
        </div>
      </div>
    </section>
  );
}

export default Result;
