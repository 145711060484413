import { Button } from 'bootstrap';
import React from 'react';
import './Imgcon.scss'
import DOMPurify from 'dompurify';
import { NavLink } from 'react-router-dom';
function Imgcon(props) {
    const imgcon=props.homeBuilder;
    const sanitizeHTML = (homeBuilders) => {
        let cleanHTML = DOMPurify.sanitize(homeBuilders);
         return cleanHTML.replace(/<\/p>|<p>|<\/p>$/g, "").replace(/^<p>$/g, "");
      };
  return (
    <section className='image-container' style={{ backgroundColor: `${imgcon.bgColor}` }}>
      <div className='container'>
        <div className='row'>
            <div className='col-lg-6 speech-bubble'>
            <div className='img'>
                <img src={`${imgcon.row.col2image.node.sourceUrl}`} className='img-fluid'/>
            </div>
            </div>
            <div className='col-lg-6 text-column'>
                <h2>{imgcon.row.col1}</h2>
                <div className="d-flex animate justify-content-start ">
                <div className="blue"></div>
                <div className="line"></div>
              </div>
              <p
              className="paragraph"
                dangerouslySetInnerHTML={{ __html: sanitizeHTML(imgcon.row.col1paragraph) }}
              />
                <h3>{imgcon.row.textHead}</h3>
                <button className="btn btn-primary">
                  <NavLink
                    className="navlink"
                    to={`${imgcon.row.buttonLink.url}`}>
                    {imgcon.row.buttonLink.title}
                  </NavLink>
                </button>
               {imgcon?.row?.imageicon?.node && ( 
                <div className=' py-3 bg-white  '>
                  <div className='d-flex justify-content-center img-icons'>
                {imgcon.row.imageicon.map((img,index)=>(
                    <img src={`${img.images.node.sourceUrl}`} className='icon-images' key={index}/>
                ))}
                </div>
                </div>
              )}
            </div>
        </div>
      </div>
    </section>
  )
}

export default Imgcon
