import React from "react";
import "./Cards.scss";
import SanitizeHTML from "../../SanitizeHTML";
function Card1(props) {
  const card= props.homeBuilder;
  return (
    <section className="card-one">
    



      <div className="container">
        <div className="row ">
          <div className="col-12 text-center ">
            <h2 dangerouslySetInnerHTML={{__html:card.heading}} />
            <div className="d-flex animate justify-content-center ">
              <div className="blues"></div>
              <div className="lines"></div>
            </div>
            <p className="paragraph" dangerouslySetInnerHTML={{__html:SanitizeHTML(card.descriptionsifAny)}}/>
          </div>
        </div>
        <div className="row py-xxl-5 py-md-4 ">
          {card.createcards.map((el,index)=>(
            <div className="col-lg-4 col-md-6 col-12 mt-4 mt-xxl-5" key={index}>
            <div className="hex" style={{backgroundColor:el.border}}>
              <div className="hex-background">
              <img src={el.cardsimage.node.sourceUrl} alt="..." className="img-fluid" style={{border:`2px solid ${el.border}`}} />
              <div className="card-body">
                <h3>{el.cardshead}</h3>
                <p className="para" dangerouslySetInnerHTML={{__html:el.cardsdescriptions}} />
              </div>
            </div>
            </div>
          </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Card1;
