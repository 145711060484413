import React, { useState, useEffect } from "react";
import "./Tech.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

function Tech(props) {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1280);
  const tech = props.homeBuilder;

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1280);
    };

    window.addEventListener("resize", handleResize);

    window.AOS.init();
window.AOS.refresh();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <section className="tech py-4" >
      <div className="container">
      <h2 className="text-center mt-4">{tech.heading}</h2>
      <div className="d-flex animate justify-content-center">
        <div className="blues"></div>
        <div className="lines"></div>
      </div>
      {isSmallScreen ? (
        <div className="container">
          <Swiper
            className="mySwiper"
            modules={[Pagination]}
            loop={true}
            pagination={{ clickable: true }}
            breakpoints={{
              375: {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              549: {
                slidesPerView: 3,
                spaceBetween: 15,
              },
              767: {
                slidesPerView: 4.5,
                spaceBetween: 10,
              },
              980: {
                slidesPerView: 5,
                spaceBetween: 20,
              },
          
            }}
          >
            {tech.firstfield.map((el,index) => (
              <SwiperSlide key={index}>
                <div className="card text-center">
                  <div className="card-body" >
                    <img
                      src={el.iconpic.node.sourceUrl}
                      className="img-fluid imgs"
                      alt="Card Icon"
                    />
                    <p className="name">{el.name}</p>
                  </div>
                </div>
              </SwiperSlide>
            ))}
            {tech.secondfield.map((el,index) => (
              <SwiperSlide key={index}>
                <div className="card  text-center ">
                  <div className="card-body" >
                    <img
                      src={el.iconimage.node.sourceUrl}
                      className="card-image imgs"
                    />
                    <p className="name">{el.iconname}</p>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      ) : (
        <div className="container icons">
            <div className="d-flex justify-content-between">
              {tech.firstfield.map((el, index) => (
                <div className="col-lg-2 col-md-4 col-sm-6" key={index}>
                  <div className="card text-center ">
                    <div className="card-body" data-aos="zoom-in" data-aos-duration="800" data-aos-once="true">
                      <img
                        src={el.iconpic.node.sourceUrl}
                        className="img-fluid imgs"
                        alt="Card Icon"
                      />
                      <h5 className="name">{el.name}</h5>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="d-flex justify-content-evenly bottom-icon">
                {tech.secondfield.map((el, index) => (
                  <div className="col-lg-2 col-md-4 col-sm-6" key={index}>
                    <div className="card  text-center ">
                      <div className="card-body" data-aos-duration="800"  data-aos="zoom-in" data-aos-once="true">
                        <img
                          src={el.iconimage.node.sourceUrl}
                          className="img-fluid imgs"
                        />
                        <h5 className="name">{el.iconname}</h5>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
          </div>
      )}
          </div>
    </section>
  );
}

export default Tech;