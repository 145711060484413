import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async';
function Seo(props) {
    const seo= props.homeBuilder;
  return (
    <HelmetProvider>
    <Helmet>
         <link rel="icon" href=".././favicon.png" />
        <title>{seo.titleOfPage}</title>
        {seo.metaTitlesAndDescriptions.map((el) =>(
            <meta name={el.metaTitles} content={el.metaDescriptions}/>
        ))}
    </Helmet>
    </HelmetProvider>
  )
}

export default Seo
