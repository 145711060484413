import React, { useEffect, useState } from "react";
import "./Circle.scss";
import SanitizeHTML from "../SanitizeHTML";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
function GridCircle(props) {
  const grid = props.homeBuilder;
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1023);
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1023);
    };

    window.addEventListener("resize", handleResize);

    window.AOS.init();
window.AOS.refresh();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section className="circle-grid">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <h2>{grid.heading}</h2>
            <div className="d-flex animate justify-content-center ">
              <div className="blues"></div>
              <div className="lines"></div>
            </div>
            <p
              className="paragraph"
              dangerouslySetInnerHTML={{
                __html: SanitizeHTML(grid.descriptionsifAny),
              }}
            />
          </div>
        </div>
        {isSmallScreen ? (
             <Swiper
             className="mySwiper"
             modules={[Pagination]}
             loop={true}
             pagination={{ clickable: true }}
             breakpoints={{
               375: {
                 slidesPerView: 2,
                 spaceBetween: 10,
               },
               549: {
                 slidesPerView: 2,
                 spaceBetween: 15,
               },
               767: {
                 slidesPerView: 3,
                 spaceBetween: 10,
               },
               980: {
                 slidesPerView: 3.5,
                 spaceBetween: 20,
               },
           
             }}
           >
             {grid.createcards.map((el,index) => (
               <SwiperSlide key={index}>
                 <div className="col-3" >
              <div className="card">
                <div className="cards">
                  <div className="card-body">
                    <h5>{el.cardshead}</h5>
                  </div>
                </div>
              </div>
            </div>
               </SwiperSlide>
             ))}
             
           </Swiper>
        ) :(
            <>
        <div className="row mt-3 mt-lg-4 mt-xl-5">
          {grid.createcards.slice(0, 4).map((el, index) => (
            <div className="col-3" key={index}>
              <div className="card">
                <div className="cards">
                  <div className="card-body">
                    <h3>{el.cardshead}</h3>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {/* <div className="col-3">
            <div className="card">
              <div className="cards">
                <div className="card-body">
                  <h3>lorem</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="col-3">
            <div className="card">
              <div className="cards">
                <div className="card-body">
                  <h3>lorem</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="col-3">
            <div className="card">
              <div className="cards">
                <div className="card-body">
                  <h3>lorem</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="col-3">
            <div className="card">
              <div className="cards">
                <div className="card-body">
                  <h3>lorem</h3>
                </div>
              </div>
            </div>
          </div> */}
        </div>
        <div className="row" style={{ placeContent: "center" }}>
          {grid.createcards.slice(4, 7).map((el, index) => (
            <div className="col-3" key={index}>
              <div className="card">
                <div className="cards">
                  <div className="card-body">
                    <h3>{el.cardshead}</h3>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {/* <div className="col-3">
            <div className="card">
              <div className="cards">
                <div className="card-body">
                  <h3>lorem</h3>
                </div>
              </div>
            </div>
            </div>
            <div className="col-3">
            <div className="card">
              <div className="cards">
                <div className="card-body">
                  <h3>lorem</h3>
                </div>
              </div>
            </div>
            </div>
            <div className="col-3">
            <div className="card">
              <div className="cards">
                <div className="card-body">
                  <h3>lorem</h3>
                </div>
              </div>
            </div>
            </div> */}
        </div>
        <div className="row" style={{ placeContent: "center" }}>
          {grid.createcards.slice(7, 9).map((el, index) => (
            <div className="col-3" key={index}>
              <div className="card">
                <div className="cards">
                  <div className="card-body">
                    <h3>{el.cardshead}</h3>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {/* <div className="col-3">
            <div className="card">
              <div className="cards">
                <div className="card-body">
                  <h3>lorem</h3>
                </div>
              </div>
            </div>
            </div>
            <div className="col-3">
            <div className="card">
              <div className="cards">
                <div className="card-body">
                  <h3>lorem</h3>
                </div>
              </div>
            </div> */}
          {/* </div> */}
        </div>
        </>
    )}

      </div>
    </section>
  );
}

export default GridCircle;
