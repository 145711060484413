import React, { useEffect, useState } from "react";
import "./Cards3.scss";
import SanitizeHTML from "../../SanitizeHTML";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
function Cards3(props) {
  const line = props.homeBuilder;
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 769);
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 769);
    };
    window.addEventListener("resize", handleResize);
    window.AOS.init();
    window.AOS.refresh();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section className="line-cards">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <h2>{line.heading}</h2>
            <div className="d-flex animate justify-content-center ">
              <div className="blue-sm"></div>
              <div className="line-sm"></div>
            </div>
            <p
              className="paragraph"
              dangerouslySetInnerHTML={{
                __html: SanitizeHTML(line.descriptionsifAny),
              }}
            />
          </div>
          {isSmallScreen ? (
               <>
               <Swiper
                 className="mySwiper"
                 modules={[Pagination]}
                 loop={true}
                 pagination={{ clickable: true }}
                 breakpoints={{
                   375: {
                     slidesPerView: 1,
                     spaceBetween: 10,
                   },
                   549: {
                     slidesPerView: 1.5,
                     spaceBetween: 15,
                   },
                   767: {
                     slidesPerView: 2,
                     spaceBetween: 10,
                   }
                 }}
               >
                 {line.createcards.map((el, index) => (
                   <SwiperSlide key={index}>
                    <div className="d-flex ">
                      <div className="card ">
                        <img
                          src={el.cardsimage.node.sourceUrl}
                          alt="..."
                          className="img-fluid"
                        />
                        <h3>{el.cardshead}</h3>
                        <p
                          className="para"
                          dangerouslySetInnerHTML={{
                            __html: SanitizeHTML(el.cardsdescriptions),
                          }}
                        />
                      </div>
                      
                    </div>
                   </SwiperSlide>
                 ))}
               </Swiper>
             </>
          ) : (
            <>
              <div className="row my-lg-4 my-xxl-5 first-row">
                {line.createcards.slice(0, 4).map((el, index) => (
                  <div className="col-3 mt-3">
                    <div className="d-flex ">
                      <div className="card ">
                        <img
                          src={el.cardsimage.node.sourceUrl}
                          alt="..."
                          className="img-fluid"
                        />
                        <h3>{el.cardshead}</h3>
                        <p
                          className="para"
                          dangerouslySetInnerHTML={{
                            __html: SanitizeHTML(el.cardsdescriptions),
                          }}
                        />
                      </div>
                      {index !== 3 && index !== 3 && index !== 6 && (
                        <div className="vl"></div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
              <div
                className="row my-lg-4  my-xxl-5 second-row"
                style={{ placeContent: "center" }}
              >
                {line.createcards.slice(4, 7).map((el, index) => (
                  <div className="col-3 mt-3">
                    <div className="d-flex ">
                      <div className="card ">
                        <img
                          src={el.cardsimage.node.sourceUrl}
                          alt="..."
                          className="img-fluid"
                        />
                        <h3>{el.cardshead}</h3>
                        <p
                          className="para"
                          dangerouslySetInnerHTML={{
                            __html: SanitizeHTML(el.cardsdescriptions),
                          }}
                        />
                      </div>
                      {index !== 2 && index !== 3 && <div className="vl"></div>}
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}

          {/* <div className="col-3" style={{ borderRight: "2px dashed black" }}>
            <div className="card">
              <img src="..." alt="..." />
              <h3>Discovery</h3>
              <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit.</p>
            </div>
          </div>
          <div className="col-3">
            <div className="d-flex">
              <div className="card">
                <img src="..." alt="..." />
                <h3>Discovery</h3>
                <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit.</p>
              </div>
              <div className="vl"></div>
            </div>
          </div>
          <div className="col-3" style={{ borderRight: "2px dashed black" }}>
            <div className="card">
              <img src="..." alt="..." />
              <h3>Discovery</h3>
              <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit.</p>
            </div>
          </div>
          <div className="col-3" style={{ borderRight: "2px dashed black" }}>
            <div className="card">
              <img src="..." alt="..." />
              <h3>Discovery</h3>
              <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit.</p>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
}

export default Cards3;
