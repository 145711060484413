import React, { useEffect, useState } from "react";
import "./WebDev.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import SanitizeHTML from "../../../SanitizeHTML";
function WebDev(props) {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1280);
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1280);
    };

    window.addEventListener("resize", handleResize);

    window.AOS.init();
window.AOS.refresh();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
    const web = props.homeBuilder;
   
  return (
    <section className="web-dev">
      <div className="container">
        <div className="text-center py-5">
          <h2>
            {web.heading}
          </h2>
          <div className="d-flex animate justify-content-center">
            <div className="blue"></div>
            <div className="line"></div>
          </div>
          <p className="paragraph"
            dangerouslySetInnerHTML={{
              __html: SanitizeHTML(web.descriptionsifAny,)
            }}
          />
        </div>
        <div className="row pb-5">

        { isSmallScreen ? (
          <Swiper
            className="mySwiper"
            modules={[Pagination]}
            loop={true}
            pagination={{ clickable: true }}
            breakpoints={{
              375: {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              549: {
                slidesPerView: 3,
                spaceBetween: 105,
              },
              767: {
                slidesPerView: 4.5,
                spaceBetween: 140,
              },
              980: {
                slidesPerView: 3,
                spaceBetween: 90,
              },
          1023: {
            slidesPerView: 3.5,
            spaceBetween: 30,
          }
            }}
          >
            {web.createcards.map((el, index) => (
              <SwiperSlide key={index}>
               {/* <div className={`col-lg-2 ${index !== 0 ? 'm-left' : 'ms-2'}`} key={index}> */}
                <div className='card' style={{border:`6px solid ${el.border}`}}>
                  
               <div className='card-body'>
               <div className="text-center">
               { el.cardimage?.node?.sourceUrl && (<img src={`${el.cardimage.node.sourceUrl}`} alt='..' className='img-fluid'/>)}
                <h3>{el.cardshead}</h3>
                </div>
                </div>
                </div>
            {/* </div>  */}
              </SwiperSlide>
            ))}
            
          </Swiper>
      ) : (
        <>
          {web.createcards.map((el,index)=>(
        <div className={`col-lg-2 ${index !== 0 ? 'm-left' : 'ms-2'}`} key={index}>
                <div className='card' style={{border:`6px solid ${el.border}`}}>
                  
               <div className='card-body'>
               <div className="text-center">
               { el.cardimage?.node?.sourceUrl && (<img src={`${el.cardimage.node.sourceUrl}`} alt='..' className='img-fluid'/>)}
                <h3>{el.cardshead}</h3>
                </div>
                </div>
                </div>
            </div> 
            ))}
            </>
          )}
        </div>
      </div>
    </section>
  );
}

export default WebDev;
