import React, { useState } from "react";
import { useForm } from "react-hook-form";    

function ContactForm(props) {
  const contacts = props.contact;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitMessage, setSubmitMessage] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    const formDataObj = new FormData();
    formDataObj.append("fullName", data.fullName);
    formDataObj.append("email", data.email);
    formDataObj.append("phoneNumber", data.phoneNumber);
    formDataObj.append("Subject", data.Subject);
    formDataObj.append("Message", data.Message);

    const Sheet_Url =
      "https://script.google.com/macros/s/AKfycbw1dIotdAWfWklpAv2boOjIoe1HchuGCY2884uAXLeJ2qVAjvVJLpR5Tmh_iRL_WkY/exec";

    try {
      const response = await fetch(Sheet_Url, {
        method: "POST",
        body: formDataObj,
        muteHttpExceptions: true,
      });

      if (response.ok) {
        setSubmitMessage("Thanks For Submitting The Form. We Will Be In Touch");
        reset();
        setFormSubmitted(true);
      } else {
        throw new Error("Failed to submit form data");
      }
    } catch (error) {
      console.error("Error:", error);
      setSubmitMessage("Failed to submit form data. Please try again later.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <h2>{contacts.heading}</h2>
      <p className="paragraph">{contacts.description}</p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-4 col-12 pt-4">
          <input
            name="fullName"
            type="text"
            placeholder={`${contacts.form.contactformlabels.firstinput}`}
            className="form-control paragraph"
            {...register("fullName")}
            required
          ></input>
        </div>
        <div className="mt-2 mb-4 col-12">
          <input
            name="email"
            type="e-mail"
            placeholder={`${contacts.form.contactformlabels.secondinput}`}
            className="form-control paragraph"
            {...register("email", {
              required: "Email is required",
              pattern: {
                value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                message: "Email is not Valid",
              },
            })}
            required
          ></input>
          {errors.email && <p className="errorMsg">{errors.email.message}</p>}
        </div>
        <div className="col-12 mb-4 mt-2">
          <input
            name="phoneNumber"
            type="text"
            placeholder={`${contacts.form.contactformlabels.thirdinput}`}
            className="form-control input-lg paragraph"
            {...register("phoneNumber", {
              pattern: {
                required: "Enter number",
                value: /^\d{10}$/,
                message: "Enter 10 Digit Number",
              },
            })}
            required
          ></input>
          {errors.phoneNumber && (
            <p className="errorMsg">{errors.phoneNumber.message}</p>
          )}
        </div>
        <div className="col-12 mb-4 mt-2">
          <input
            name="Subject"
            type="tell"
            placeholder={`${contacts.form.contactformlabels.fourthInput}`}
            className="form-control input-lg paragraph"
            {...register("Subject")}
            required
          ></input>
        </div>
        <div className="col-12 mb-4 mt-2">
          <textarea
            placeholder={`${contacts.form.contactformlabels.fiveinput}`}
            rows="5"
            name="Message"
            className="form-control paragraph"
            {...register("Message", {})}
            required
          ></textarea>
        </div>
        <div className="col-6 my-3">
          {isSubmitting || formSubmitted ? (
            <p className="text-primary paragraph">{submitMessage}</p>
          ) : (
            <button type="submit" className="btn btn-primary">
              {contacts.form.buttonname}
            </button>
          )}
        </div>
      </form>
    </>
  );
}

export default ContactForm;
