import React from "react";
import "./Img.scss";
import DOMPurify from "dompurify";
import { NavLink } from "react-router-dom";
function ImgLeft(props) {
  const image = props.homeBuilder;
  const sanitizeHTML = (homeBuilders) => {
    let cleanHTML = DOMPurify.sanitize(homeBuilders);
     return cleanHTML.replace(/<\/p>|<p>|<\/p>$/g, "").replace(/^<p>$/g, "");
  };
  return (
    <section
      className="content py-3 py-xl-5"
      style={{ backgroundColor: `${image.bgColor}` }}
    >
      <div className="container py-4">
        <div className="row">
        <div className={`col-lg-6 content-imageLeft`}>
            <img
              src={`${image.row.col2image.node.sourceUrl}`}
              className="img-fluid content-img"
      
            />
          </div>
          <div
            className={`col-lg-6 headerLeft`}
         
          >
            <h2>{image.row.col1}</h2>
            <div className="d-flex animate justify-content-start ">
              <div className="blues"></div>
              <div className="lines"></div>
            </div>
            <p className="paragraph"
              dangerouslySetInnerHTML={{ __html: sanitizeHTML(image.row.col1paragraph) }}
            />
              {image.row.buttonLink && image.row.buttonLink.url && (
                <button className="btn btns">
                  <NavLink
                    className="navlink"
                    to={`${image.row.buttonLink.url}`}
                  >
                    {image.row.buttonLink.title}
                  </NavLink>
                </button>
              )}
          </div>
        
        </div>
      </div>
    </section>
  );
}

export default ImgLeft;
