import React from 'react'
import './Images.scss'
function Image (props) {
    const contains= props.homeBuilder;
  return (
    <section className='head-image py-5'>
    <div className='container '>
    <div className='row py-lg-5 py-sm-3'>
<div className='col-12 text-center'>
        <h2  >{contains.row.col1}</h2>
        <div className="d-flex animate justify-content-center">
                <div className="blue-lg"></div>
                <div className="line-lg"></div>
              </div> 
      <img src={`${contains.row.col2image.node.sourceUrl}`} className='img-fluid mt-4'/>
    </div>
    </div>
    </div>
    </section>
  )
}

export default Image
