import 'swiper/css';
import React, { useEffect } from 'react';
import { SwiperSlide ,Swiper} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import {  Pagination  } from 'swiper/modules';
import './Portfolio.scss'
 export default function Portfolio(props) {
  useEffect(() => {
    window.AOS.init();
  }, []);
    const port=props.homeBuilder
  return (
      <section className="carousel " style={{backgroundColor:port.backgroundColor}}>
      <div className="container py-4" >
<div className="row">
  <h2 className="text-center">{port.heading}</h2>
  <div className="d-flex animate justify-content-center ">
  <div className="blue-xs"></div>
  <div className="line-xs"></div>
</div>
  <Swiper
  
className="mySwiper"

      modules={[ Pagination]}
      breakpoints={{
        320: {
          slidesPerView: 1.5,
          spaceBetween: 10,
        },
        640: {
          slidesPerView: 2,
          spaceBetween: 15,
        },
      992: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        1500: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        1800 :{
          slidesPerView: 3,
          spaceBetween :25,
        },
        2500 : {
          slidesPerView: 3,
          spaceBetween: 20,
        }
      }}
      pagination={{ clickable: true }}
      
    >
           {port.imageicon.map((el, index) => (
          
           <SwiperSlide className='py-5' key={index}  data-aos="flip-left"
           data-aos-easing="ease-in-cubic"
           data-aos-duration="600"
           data-aos-offset="400" data-aos-once="true" > <img src={el.images.node.sourceUrl} className="carousel-images" alt={`carousel-item-${index}`} />
           </SwiperSlide> 
          ))}
    </Swiper>
    </div>
    </div>
</section>

  )
}

