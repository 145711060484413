import React from "react";
import "./ReactDev.scss";
import SanitizeHTML from "../../SanitizeHTML";
function ReactDev(props) {
  const cards = props.homeBuilder;

  return (
    <section className="react-development  mt-xxl-4 mb-lg-5 mb-md-3">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center py-4">
            <h2>{cards.heading}</h2>
            <div className="d-flex animate justify-content-center">
              <div className="blues"></div>
              <div className="lines"></div>
            </div>
            <p
              className="paragraph"
              dangerouslySetInnerHTML={{
                __html:SanitizeHTML(cards.descriptionsifAny),
              }}
            />
          </div>
          {cards.createcards.map((el, index) => (
            <div className="col-lg-4 col-sm-6 mt-4 mt-xxl-5" key={index}>
                 <div className="card" style ={{border: `2px solid ${el.border}`}}>
              <div className="vl" style={{backgroundColor: el.border}} />
              <div className="card-body">
                <h3>{el.cardshead}</h3>
                <p
                    className="card-text para"
                    dangerouslySetInnerHTML={{
                      __html:SanitizeHTML(el.cardsdescriptions),
                    }}
                  />
              </div>
            </div>
            </div>
          ))}
         
        </div>
      </div>
    </section>
  );
}

export default ReactDev;
