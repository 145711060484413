import React from "react";
import "./Line.scss";
function Line(props) {
  let Line = props.homeBuilder;
  return (
    <section className="Line-cards">
      <div className="container mb-3">
        <div className="row pt-5">
          <h2 className="text-center" dangerouslySetInnerHTML={{__html:Line.heading}}/>
          <div className="d-flex animate justify-content-center">
            <div className="blues"></div>
            <div className="lines"></div>
          </div>
          {Line.description && (
            <p className="text-center center" dangerouslySetInnerHTML={{__html:Line.description}}/>
          )}
        </div>
        <div className="container lines-card text-center mt-2">
          <div className="row ">
         
          <div className="col-lg-4 moves-1 col-md-6">
            <div className="card">
              <div className="card-body">
                <h5>{Line.card1Head}</h5>
                <p
                    className="card-text"
                    dangerouslySetInnerHTML={{ __html: Line.cards1Description }}
                  />
              </div>
            </div>
          <div className="vl"/>    
          </div>
          <div className="col-lg-4  moves-1 col-md-6">
            <div className="card">
              <div className="card-body">
              <h5>{Line.card2Head}</h5>
                <p
                    className="card-text"
                    dangerouslySetInnerHTML={{ __html: Line.card2Description }}
                  />
              </div>
            </div>
          <div className="vl"/>    
          </div>
          <div className="col-lg-4  col-md-6">
            <div className="card">
              <div className="card-body">
              <h5>{Line.card3Head}</h5>
                <p
                    className="card-text"
                    dangerouslySetInnerHTML={{ __html: Line.card3Description }}
                  />
              </div>
            </div>
          <div className="vl"/>
          </div>
       <div className="col-lg-4  moves-2 col-md-6">
            <div className="card">
              <div className="card-body">
              <h5>{Line.card4Head}</h5>
                <p
                    className="card-text"
                    dangerouslySetInnerHTML={{ __html: Line.card4Description }}
                  />
              </div>
            </div>
          </div>
          <div className="col-lg-4  moves-2 col-md-6">
            <div className="card">
              <div className="card-body">
              <h5>{Line.card5Head}</h5>
                <p
                    className="card-text"
                    dangerouslySetInnerHTML={{ __html: Line.card5Description }}
                  />
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="card">
              <div className="card-body">
              <h5>{Line.card6Head}</h5>
                <p
                    className="card-text"
                    dangerouslySetInnerHTML={{ __html: Line.card6Description }}
                  />
              </div>
            </div>
          </div>
       </div>
     
        </div>
        </div>
    </section>
  );
}

export default Line;
